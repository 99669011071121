//通用颜色
$primary-color: #8372ff !default;
$normal-color: #979798 !default;
$white-color-1: #ffffff !default;
$white-color-2: #f7f7fa !default;
$gray-color-2: #5e5f62 !default;
$black: #0f0f10 !default;
$deep-black: #1b1b1b !default;
$dark: #2e2f30 !default;
$deep-dark: #000000 !default;

$deep-gray: #353536 !default;
$gray: #3b3c3d !default;
$low-gray: #5e5f62 !default;

$white: #d8d8d8 !default;
$lightless-white: #e3e3e3 !default;
$light-white: #ffffff !default;
$darkless-white: #a1a1a2 !default;
$darkest-white: #898a8b !default;

$border-gray: #434445 !default;
$border-light: #a1a1a2 !default;

@import "./assets/sass/variables/variables";
@import "./assets/sass/minxin.scss";

.ant-menu-vertical {
  .ant-menu-item {
    display: flex;
    align-items: center;
    svg {
      margin-right: 10px;
    }
  }
}

//select
.ant-select {
  @include font_color("font-normal");
  font-size: 14px;
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    @include background_color("bg-hight");
    border: none;
    border-radius: 4px;
  }
  .ant-select-arrow {
    @include font_color("font-normal");
    top: 60%;
  }
}

//select dropdown
.ant-select-dropdown {
  @include background_color("bg-hight");
  @include font_color("font-normal");
  @include border_raduis("raduis-secondary");
  .ant-select-item {
    @include font_color("font-normal");
    font-size: 12px;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: $primary-color;
    color: $light-white;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: $primary-color;
    color: $light-white;
  }
}

//message
.ant-message {
  top: 10%;
}

//tooltip
.ant-tooltip {
  font-size: 14px;
  z-index: 9;
  max-width: 200px;
  .ant-tooltip-arrow {
    display: none;
  }
  .ant-tooltip-inner {
    @include background_color("tooltip-bg");
    @include font_color("font-normal");
    padding: 8px 12px;
    min-width: 50px;
    min-height: 24px;
    line-height: 10px;
    text-align: center;
    border-radius: 4px;
  }
}
.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
  padding-bottom: 4px;
}

//tab 标签
.ant-tabs {
  color: inherit;
  &.ant-tabs-card {
    .ant-tabs-nav {
      flex: 2;
      margin-bottom: 0;
      border-radius: 0;
      .ant-tabs-nav-list {
        width: 100%;
        display: flex;
        justify-content: space-around;
        .ant-tabs-tab {
          margin: 0;
          background: $gray;
          border-color: $border-gray;
          padding: 0.5rem;
          font-size: 14px;
          flex: 2;
          border-radius: 0;

          & > div {
            margin: auto;
          }

          &.ant-tabs-tab-active {
            background: transparent;
            border-bottom: 0;
          }
        }
      }
    }
    .ant-tabs-content-holder {
      .ant-tabs-tabpane {
        padding: 1rem 0.5rem;
        border-width: 0 1px 1px 1px;
        border-color: $border-gray;
        border-style: solid;
      }
    }
  }
  .ant-tabs-nav {
    &::before {
      border: none !important;
    }
  }
  .ant-tabs-nav-list {
    width: 100%;
    display: flex;
    justify-content: space-around;
    .ant-tabs-tab {
      margin: 0;
      padding: 0 0 4px;
      flex: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      // border-bottom: 2px solid $border-gray;
      @include border("border-gray", 2px, solid, border-bottom);
    }
  }
}

//slider
.ant-slider.normal {
  .ant-slider-rail {
    height: 2px;
    @include background_color("work-slider-bg");
  }
  .ant-slider-track {
    height: 2px;
    background-color: $primary-color;
  }
  .ant-slider-step {
    height: 2px;
  }
  .ant-slider-mark-text {
    color: $white;
  }
  .ant-slider-handle {
    border-radius: 0;
    background-color: #ffbb00;
    width: 8px;
    height: 8px;
    box-shadow: none;
    border-color: #ffbb00;
    &::after {
      content: "";
      width: 0;
      height: 0;
      border-top: solid 5px #ffbb00;
      border-left: solid 4px transparent;
      border-right: solid 4px transparent;
      position: absolute;
      left: -2px;
      top: 6px;
    }
  }
  .ant-slider-handle.ant-tooltip-open {
    border-color: #ffbb00;
    background-color: #ffbb00;
    &::after {
      border-top: solid 5px #ffbb00;
    }
  }
}

//input
.ant-input {
  background-color: $gray;
  color: $white;
  border-radius: 0;
  border: none;
}
.ant-input-borderless,
.ant-input-borderless:hover,
.ant-input-borderless:focus,
.ant-input-borderless-focused,
.ant-input-borderless-disabled,
.ant-input-borderless[disabled] {
  background-color: $gray;
}

//buttom
.ant-bottom {
  border-radius: 4px;
}
.ant-btn-primary {
  text-shadow: none !important;
}

// 弹窗
.ant-modal {
  padding: 0 !important;
  max-width: 100% !important;
  margin: 0;
}
.ant-modal-mask {
  @include background_color("modal-mask-bg");
}

//抽屉
.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: none !important;
}
