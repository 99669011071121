//通用颜色
$primary-color: #8372ff !default;
$normal-color: #979798 !default;
$white-color-1: #ffffff !default;
$white-color-2: #f7f7fa !default;
$gray-color-2: #5e5f62 !default;
$black: #0f0f10 !default;
$deep-black: #1b1b1b !default;
$dark: #2e2f30 !default;
$deep-dark: #000000 !default;

$deep-gray: #353536 !default;
$gray: #3b3c3d !default;
$low-gray: #5e5f62 !default;

$white: #d8d8d8 !default;
$lightless-white: #e3e3e3 !default;
$light-white: #ffffff !default;
$darkless-white: #a1a1a2 !default;
$darkest-white: #898a8b !default;

$border-gray: #434445 !default;
$border-light: #a1a1a2 !default;

@import "./sass/variables/variables";
@import "./sass/minxin.scss";

.Home {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  @media (min-width: 992px) {
    flex-direction: row;
    height: 100%;
  }
  .Mobile-top-navbar {
    @include background_color("bg-secondary");
    @include border("navbar-right-border-color", 1px, solid, border-bottom);
    border-bottom: 1px solid $border-gray;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3px;
    a {
      @include font_color("font-normal");
      &.logo {
        @include font_color("mobile-logo-color");
      }
    }
    @media (min-width: 992px) {
      display: none;
    }
  }
  .Loadding-cont {
    @include background_color("bg-secondary");
    width: 100%;
    height: calc(100vh - 103px);
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: 992px) {
      height: 100%;
    }
  }
  .Stage {
    display: flex;
    height: calc(100% - 105px);
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    flex: 2;
    @include background_color("bg-main");
    justify-content: center;
    @media (min-width: 992px) {
      flex-direction: row;
      justify-content: baseline;
      height: 100%;
      @include background_color("preview-bg");
    }
    .left {
      flex: 0 0 170px;
      max-width: 170px;
      position: relative;
      display: none;
      @media (min-width: 992px) {
        display: block;
      }
      .collspan {
        position: absolute;
        top: 0;
        right: -20px;
        padding: 2px 4px 2px 2px;
        border-top-right-radius: 100px;
        border-bottom-right-radius: 100px;
        background-color: $dark;
        border: none;
        cursor: pointer;
        color: $white;
        z-index: 9;
        &:focus {
          outline: none;
        }
      }
      &.hidden {
        flex: 0 0 0px;
        width: 0;
      }
      .tips {
        position: absolute;
        top: 0;
        left: 40px;
        padding-top: 0.5rem;
        z-index: 9;
      }
    }
    .center {
      flex: 0 0 calc(100% - 220px);
      max-width: 100%;
      padding-right: 0;
      transition: 0.3s;
      &.open {
        padding: 0;
      }
      @media (min-width: 992px) {
        flex: 1 1 auto;
        padding: 12px 16px 12px 0;
        &.open {
          padding: 12px 300px 12px 0;
        }
      }
    }
    .right {
      flex: 0;
      height: 100%;
      margin-top: 32px;
      @media (min-width: 992px) {
        @include border("work-left-border-color", 1px, solid, border-left);
        flex: 0 0 300px;
        max-width: 300px;
        margin-top: 0;
      }
    }
    // .mobile-workplace {
    //   display: block;
    //   @media (min-width: 992px) {
    //     display: none;
    //   }
    // }
    .ant-drawer {
      display: none;
      @media (min-width: 992px) {
        display: block;
      }
    }
    .ant-drawer {
      @include background_color("preview-bg");
      transition: 0.3s;
      &.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
        box-shadow: none;
      }
      &.ant-drawer-right.ant-drawer-open.no-mask {
        width: 300px !important;
        transform: translateX(0px);
        right: 0;
      }
      .ant-drawer-content {
        @include background_color("navbar-right-border-color");
      }
      .ant-drawer-body {
        padding: 0;
      }
    }
  }
  .fetchModal {
    .uploading {
      @include font_color("font-normal");
      text-align: center;
      font-size: 1.2rem;
      .timeIcon {
        margin: auto;
        display: block;
        width: 60px;
        height: 60px;
        background: url(./images/timg.svg) no-repeat center center/100%;
      }
      .msg {
        display: flex;
        align-items: center;
        margin-top: 1rem;
        p {
          margin: 0;
          max-width: 400px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 1.1rem;
        }
        .close {
          display: flex;
          align-items: center;
          cursor: pointer;
          margin-left: 1.2rem;
          font-size: 14px;
          svg {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
  .FileListModal {
    .ant-drawer-content {
      @include background_color("filelist-bg");
      width: 100%;
      height: calc(100% - 60px);
      margin-top: 60px;
      transition: 0.5s;
      overflow: hidden;
      .ant-drawer-body {
        padding: 3.75rem 1.5rem 42px;
        width: 100%;
        height: 100%;
        // box-sizing: content-box;
        box-sizing: border-box;
        & > div > div {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }
      .Files {
        flex: 2;
        overflow-y: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar {
          width: 4px;
          @include background_color("scrollbar-track-bg");
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          @include background_color("scrollbar-thumb-bg");
        }
        &::-webkit-scrollbar-track {
          border-radius: 4px;
          @include background_color("scrollbar-track-bg");
        }
        .fileItem {
          @include border_raduis("raduis-normal");
          overflow: hidden;
          width: 150px;
          height: 86px;
          position: relative;
          cursor: pointer;
          background: url("./images/timg.svg") no-repeat center center;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          &:hover {
            .fileItem-modal {
              display: flex;
            }
          }
          .fileItem-modal {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.6);
            display: none;
            align-items: flex-end;
            color: $white-color-1;
            .more-btn {
              position: absolute;
              top: 0;
              right: 4px;
            }
            .fileItem-info {
              padding: 0 8px;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 12px;
              line-height: 15px;
            }
          }
          @media (min-width: 992px) {
            width: 240px;
            height: 135px;
          }
        }
        .fileItem-time {
          @include font_color("font-normal");
          text-align: left;
          font-size: 14px;
          line-height: 18px;
          margin: 0.5rem 0 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 1.5rem;
          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
      .tips {
        @include font_color("font-secondary");
        margin: 1.5rem 0 0;
        display: flex;
        align-items: center;
        svg {
          margin-right: 0.5rem;
        }
      }
    }
  }
  .ant-modal-mask + div {
    .FileListModal {
      .ant-modal-content {
        height: calc(100% - 60px);
      }
    }
  }
  .ant-modal-mask.ant-modal-mask-hidden + div {
    .FileListModal {
      .ant-modal-content {
        height: 0;
      }
    }
  }

  .tipsModal,
  .addMusicTrimAudioModal {
    @include border_raduis("modal-border-raduis");
    overflow: hidden;
    box-shadow: 0px 22px 84px 0px rgba(8, 0, 25, 0.3);
    padding: 0;
    .ant-modal-close-x {
      @include font_color("font-secondary");
      &::before {
        display: none;
      }
    }
    .ant-modal-body {
      padding: 50px 30px 30px;
      width: 100%;
      height: 100%;
      .tip-cont {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      svg {
        width: 1.5rem;
        height: 1.5rem;
        flex-shrink: 0;
      }
      .ant-btn {
        border-radius: 4px;
        font-weight: bold;
      }
    }
  }

  .tipsProductModal {
    width: fit-content !important;
    max-width: 100%;
    .ant-modal-content {
      max-width: 930px;
    }
    .ant-modal-body {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      padding: 0;
      .m-header {
        padding: 1.1rem 1.1rem 1.1rem 2.25rem;
        display: flex;
        align-items: center;
        @include border("border-gray", 1px, solid, border-bottom);
        svg {
          width: 1.5rem;
          height: 1.5rem;
          flex-direction: 0;
          margin-right: 20px;
        }
      }
      .m-cont {
        padding: 2.38rem 2.25rem 3.15rem 2.25rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          text-align: center;
        }
        .lft {
          width: 200px;
          flex-shrink: 0;
          img {
            max-width: 100%;
          }
        }
        .rgt {
          margin-left: 0px;
          p {
            font-size: 1.5rem;
            margin-bottom: 30px;
          }
          ul {
            padding-left: 1rem;
            margin-bottom: 40px;
            li {
              &:not(:last-child) {
                margin-bottom: 0.8rem;
              }
            }
          }
          .btn-groups {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
          }
          .ant-btn {
            min-width: 200px;
            height: 60px;
            font-size: 18px;
            line-height: 18px;
            border-radius: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 6.4px;
            margin-top: 10px;
            svg {
              margin-right: 0.6rem;
            }
            &:first-child {
              margin-right: 38px;
            }
          }
        }
      }
    }
    @media (min-width: 768px) {
      .ant-modal-body {
        .m-cont {
          flex-direction: row;
          align-items: flex-start;
          p {
            text-align: left;
          }
          .rgt {
            margin-left: 60px;
          }
        }
      }
    }
  }

  .shareModal {
    h1 {
      color: $white;
      text-align: center;
      font-size: 28px;
      line-height: 35px;
      margin-bottom: 2.25rem;
    }
    .society {
      width: 276px;
      margin: 0 auto 46px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        display: block;
        cursor: pointer;
      }
    }
    p {
      color: $normal-color;
    }
    .link-copy {
      width: 320px;
      height: 40px;
      margin: auto;
      background-color: $gray;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 12px;
      color: $normal-color;
      .copy {
        color: $primary-color;
        cursor: pointer;
      }
    }
  }

  .processModal {
    &.ant-drawer-open {
      width: 100% !important;
    }
    & > * {
      transition: none;
    }
    .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
      box-shadow: none;
    }
    .ant-drawer-content {
      width: 100%;
      @include background_color("preview-bg");
      .ant-drawer-body {
        width: 100%;
        height: 100%;
        padding: 12px 16px 12px 16px;
        .process-cont {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          @include background_color("bg-main");
          @include border_raduis("preview-raduis");
        }
        .uploading {
          //普通进度弹窗样式
          max-width: 100%;
          &.normalCont {
            @include border_raduis("process-normal-cont-raduis");
            @include background_color("process-normal-cont-bg");
            max-width: calc(100% - 48px);
            width: 480px;
            height: 269px;
            position: absolute;
            overflow: hidden;
            &.vertical {
              width: 300px;
              height: auto;
            }
            &.horizontal {
              width: 480px;
              height: auto;
            }
            & .mask {
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              background: rgba(0, 0, 0, 0.6);
              z-index: 2;
            }
            & > img {
              position: relative;
              z-index: 1;
            }
            .progress .ant-progress-outer .ant-progress-inner {
              background: transparent;
            }
            .progress {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: 3;
              .ant-progress {
                width: 100%;
                height: 100%;
              }
              .ant-progress-outer {
                width: 100%;
                height: 100%;
                .ant-progress-inner {
                  background: transparent;
                  width: 100%;
                  height: 100%;
                  border-radius: 0;
                  .ant-progress-bg {
                    height: 100% !important;
                    border-radius: 0;
                    background: rgba(131, 114, 255, 0.4) !important;
                  }
                }
              }
              .close {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                @include font_color("process-normal-cont-color");
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .precent-num {
                  font-weight: bold;
                  font-size: 36px;
                  line-height: 45px;
                  margin-bottom: 0.5rem;
                }
                .percent-title {
                  font-size: 16px;
                  line-height: 16px;
                  margin-bottom: 1.5rem;
                }
                svg {
                  cursor: pointer;
                }
              }
            }
            img {
              width: 100%;
            }
          }
          //进度引导弹窗样式
          &.guideToDownloadCont {
            @include font_color("font-normal");
            .percent-title {
              text-align: center;
              margin-bottom: 1rem;
            }
            .progress {
              display: flex;
              align-items: center;
              width: 440px;
              max-width: 100%;
              margin: auto;
              .ant-progress {
                position: relative;
                .ant-progress-inner {
                  @include background_color("process-progress-bg");
                }
                .ant-progress-outer {
                  padding: 0;
                }
                .ant-progress-bg {
                  height: 14px !important;
                }
                .ant-progress-text {
                  font-size: 12px;
                  @include font_color("font-normal");
                  position: absolute;
                  top: calc(50% + 1px);
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
              }
              .close {
                margin-left: 1rem;
                cursor: pointer;
                svg {
                  display: block;
                }
              }
            }
            .product-card {
              box-shadow: 0px 0px 6px rgba(131, 114, 255, 0.4);
              border-radius: 4px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              max-width: 316px;
              // background-color: $deep-gray;
              padding: 32px;
              margin: 60px auto 0;
              @include font_color("font-normal");
              @include background_color("process-product-card-bg");
              @include border_raduis("process-product-card-raduis");
              h4 {
                @include font_color("font-normal");
                margin-top: 1rem;
                font-weight: bold;
                font-size: 1rem;
              }
              ul {
                padding-left: 20px;
                li {
                  font-size: 14px;
                  line-height: 20px;
                  margin-bottom: 4px;
                  word-break: break-all;
                }
              }
              .ant-btn-primary {
                min-width: 180px;
                border-radius: 4px;
              }
              .ant-btn-link span {
                text-decoration: underline;
                margin-top: 6px;
              }
            }
          }
        }
      }
    }
    @media (min-width: 992px) {
      &.ant-drawer-open {
        width: calc(100% - 92px) !important;
      }
      .ant-drawer-content {
        .ant-drawer-body {
          padding: 12px 16px 12px 0;
        }
      }
    }
  }

  .purchaseModal {
    font-size: 16px;
    @include border_raduis("modal-border-raduis");
    @include font_color("purchase-color");
    overflow: hidden;
    .ant-modal-content .ant-modal-body {
      padding: 40px 32px;
    }
    h1 {
      @include font_color("purchase-color");
      font-weight: 900;
      font-size: 24px;
      text-align: center;
      max-width: 444px;
      margin: 0 auto 32px;
    }
    .price-content {
      @include border("border-gray");
      @include border_raduis("raduis-secondary");
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 42px;
      .change-package {
        @include font_color("purchase-color-secondary");
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 12px;
        .ant-switch {
          @include background_color("border-gray");
          margin: 0 8px;
          height: 7px;
          .ant-switch-handle {
            top: -3px;
            &::before {
              @include border("border-normal");
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .tips {
        color: $primary-color;
        background-color: rgba(131, 114, 255, 0.13);
        padding: 0px 8px;
        @include border_raduis("raduis-normal");
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: -8px;
          right: 10px;
          border-width: 4px 6px 4px 6px;
          border-color: transparent rgba(131, 114, 255, 0.13) rgba(131, 114, 255, 0.13) transparent;
          border-style: solid;
        }
      }
      .price {
        @include font_color("purchase-color");
        margin-top: 18px;
        .currency {
          font-size: 18px;
          line-height: 23px;
          top: -16px;
          position: relative;
        }
        .price-number {
          font-size: 44px;
          line-height: 55px;
          font-weight: 700;
        }
        .currency-area {
          font-weight: 700;
        }
      }
      .total-price {
        width: 100%;
        margin-top: 19px;
        @include font_color("purchase-color");
        & > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 16px;
          &.discount {
            margin-bottom: 4px;
            label {
              @include font_color("purchase-color-secondary");
            }
            .code {
              color: $primary-color;
              cursor: pointer;
            }
            .ant-input-group {
              @include border_raduis("raduis-normal");
              @include border("purchase-input-border");
              display: flex;
              width: 180px;
              overflow: hidden;
              input {
                border: none;
                @include font_color("font-normal");
                @include background_color("purchase-input-bg");
                border-right: none !important;
              }
              .ant-btn {
                @include border_raduis("raduis-normal");
                background-color: rgba(131, 114, 255, 0.13);
                border-left: none;
                border: none;
                color: $primary-color;
                display: flex;
                align-items: center;
                justify-content: center;
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
              }
            }
          }
          &.total {
            font-weight: 700;
          }
        }
      }
      .pruchase-btn {
        margin-top: 20px;
        width: 100%;
        height: 48px;
        font-weight: bold;
        font-size: 18px;
        line-height: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include border_raduis("raduis-normal");
      }
    }
    .list {
      list-style: none;
      padding-left: 0;
      margin-top: 32px;
      @include font_color("purchase-color");
      li {
        display: flex;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 12px;
        &::before {
          // content: url('data:image/svg+xml,<svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M13.1173 0.617205C13.4479 0.947795 13.4615 1.47945 13.1482 1.82647L5.5971 10.1898C5.38645 10.4231 5.02214 10.4288 4.80425 10.2023L0.618497 5.85003C0.275035 5.49291 0.280574 4.92659 0.630955 4.57625C0.958969 4.24828 1.48123 4.21965 1.84313 4.50981L4.91358 6.97163C5.08104 7.10589 5.32254 7.09336 5.47521 6.94249L11.8793 0.613552C12.2227 0.274113 12.7759 0.275744 13.1173 0.617205Z" fill="%23ffffff"/></svg>');
          @include content("purchase-list-before-content");
          margin-right: 8px;
        }
      }
    }
    .tips-cont {
      margin-top: 36px;
      @include font_color("purchase-color-secondary");
      span {
        display: block;
      }
    }
  }
}

.ant-modal-content {
  @include background_color("modal-bg");
  @include font_color("font-normal");
  box-shadow: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  .ant-modal-close-x {
    width: fit-content;
    padding: 0 1rem;
    font-size: 12px;
    svg {
      margin-left: 0.8rem;
      font-size: 1rem;
    }
    &::before {
      display: none;
    }
  }
  .ant-modal-body {
    padding: 2.375rem 2rem;
    max-width: 100%;
    max-height: 100%;
    @media (min-width: 992px) {
      padding: 4.375rem 2rem;
    }
  }
  .ant-modal-close {
    color: $white;
  }
  .menu {
    padding: 0 1rem;
    display: flex;
    align-items: center;
    button {
      border: none;
      background-color: transparent;
      cursor: pointer;
      padding: 0;
      line-height: 0;
      &:hover {
        color: $white;
      }
      &:focus {
        outline: none;
      }
    }
  }
  .ant-typography {
    color: $white;
  }
  .ant-checkbox-wrapper {
    color: $white;
  }
  .btns {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    button {
      margin: 0.5rem;
      min-width: 6rem;
      @media (min-width: 992px) {
        margin: 0.5rem;
        min-width: 10rem;
        &.btn-mobile {
          display: none;
        }
      }
    }
  }
  .successModal {
    display: flex;
    justify-content: center;
    .Files {
      width: calc(356px + 2rem);
      height: 31rem;
      &.auto {
        // width: 285px;
        height: auto;
        max-height: 31rem;
        .ant-row {
          .thumbnail {
            height: 300px;
          }
        }
      }
      .ant-row {
        max-width: 100%;
        .thumbnail {
          &.active {
            border: 1px solid $primary-color;
          }
          img {
            max-width: 100%;
          }
          &.large {
            height: 200px;
          }
          .audio {
            height: 200px;
          }
          .not-allowed {
            cursor: not-allowed;
          }
          video {
            width: 100%;
            height: 100%;
            object-fit: contain;
            max-width: 100%;
            display: block;
            background-color: #0f0f10;
          }
        }
      }
    }
    .operation {
      max-width: calc(320px + 2rem);
      padding: 2rem 1rem;
      margin-left: 2rem;
      p {
        text-align: left;
      }
      .operationBtns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        list-style: none;
        padding: 0;
        li {
          cursor: pointer;
          &.btns {
            min-width: 140px;
            flex: 2;
            margin: 0 0 0 1rem;
            .ant-btn {
              margin: 0;
              line-height: 18px;
              padding: 0.5rem;
              height: auto;
              min-width: auto;
              &.notAllow {
                cursor: not-allowed !important;
              }
            }
          }
          &.notAllow {
            cursor: not-allowed !important;
          }
          &:not(.btns) {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: $gray;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
          }
          &:not(:last-child) {
            margin-right: 0.5rem;
          }
        }
      }
      .btns {
        width: 100%;
        margin-top: 1.5rem;
        .ant-btn {
          margin: 0 0 1.5rem;
          width: 100%;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
    @media (max-width: 768px) {
      & {
        flex-direction: column;
        align-items: center;
      }
      .Files {
        max-width: 100%;
        &.auto {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }
      .operation {
        max-width: 100%;
        margin-left: 0;
        .operationBtns {
          flex-wrap: wrap;
          li {
            margin-bottom: 1.2rem;
            &.btns {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
  .ant-upload.ant-upload-drag {
    height: 3rem;
    width: 3rem;
  }
  .ant-upload.ant-upload-drag .ant-upload-drag-container {
    line-height: 1;
  }
  .ant-upload.ant-upload-drag .anticon-plus {
    color: $white;
    font-size: 24px;
  }
  .ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
  }
}
