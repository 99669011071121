//通用颜色
$primary-color: #8372ff !default;
$normal-color: #979798 !default;
$white-color-1: #ffffff !default;
$white-color-2: #f7f7fa !default;
$gray-color-2: #5e5f62 !default;
$black: #0f0f10 !default;
$deep-black: #1b1b1b !default;
$dark: #2e2f30 !default;
$deep-dark: #000000 !default;

$deep-gray: #353536 !default;
$gray: #3b3c3d !default;
$low-gray: #5e5f62 !default;

$white: #d8d8d8 !default;
$lightless-white: #e3e3e3 !default;
$light-white: #ffffff !default;
$darkless-white: #a1a1a2 !default;
$darkest-white: #898a8b !default;

$border-gray: #434445 !default;
$border-light: #a1a1a2 !default;

@import "./sass/variables/variables";
@import "./sass/minxin.scss";

.addMusicTrimAudioModal {
  @include background_color("modal-bg");
  @include font_color("font-normal");
  padding-bottom: 0;
  .ant-typography {
    @include font_color("font-normal");
    text-align: center;
    margin-bottom: 3rem;
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3rem;
  }
  .controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include font_color("font-normal");
    svg {
      width: 38px !important;
      height: 38px !important;
      display: block;
      cursor: pointer;
    }
    .ant-breadcrumb,
    .ant-breadcrumb > span:last-child,
    .ant-breadcrumb-separator {
      @include font_color("font-normal");
      font-size: 12px;
      @media (min-width: 992px) {
        font-size: 14px;
      }
    }
  }
  .track {
    position: relative;
    margin: 0.5rem 0;
    height: 40px;
    .screenshots {
      display: flex;
      width: 100%;
      wave {
        width: 100%;
      }
    }
    .ant-slider {
      margin: 0;
      padding: 0;
      height: 100%;
    }
    .ant-slider-rail,
    .ant-slider-track,
    .ant-slider-step {
      height: 100%;
      background-color: transparent;
    }
    .frame {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      padding: 0.5rem 0;
      z-index: 4;
      .ant-slider-handle {
        height: 80px;
        border-radius: 0;
        width: 1px;
        border: none;
        top: -30px;
        background-color: #ff5c00;
        margin: 0;
        &::before {
          content: "";
          width: 7px;
          height: 12px;
          background-color: #ff5c00;
          position: absolute;
          left: -3px;
          top: 0;
        }
        &::after {
          content: "";
          width: 7px;
          height: 100%;
          background-color: transparent;
          position: absolute;
          left: -3px;
          top: 0;
        }
      }
    }
    .frame-merge,
    .frame-track,
    .frame-crop_rotate,
    .frame-resize {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      padding: 0.5rem 0;
      display: flex;
      justify-content: space-between;
      .ant-slider-rail {
        height: 4px;
        background-color: #f1edff;
      }
      .ant-slider-track {
        height: 4px;
      }
      .active .ant-slider-track {
        background-color: $primary-color;
      }
      .ant-slider-step {
        position: relative;
      }
      .ant-slider-mark-text,
      .ant-slider-mark-text-active {
        color: $white;
      }
      .ant-slider-handle {
        width: 12px;
        height: 12px;
        border-radius: 12px;
        border: none;
        top: -4px;
        background-color: #ffbb00;
        margin: 0;
        display: none;
      }
      .active .ant-slider-handle {
        display: block;
      }
    }
    .range {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 4;
      .ant-slider-handle {
        height: 50px;
        border-radius: 20px;
        width: 10px;
        border-left: solid 4px #f7b500;
        border-right: solid 4px #f7b500;
        border-top: solid 16px #f7b500;
        border-bottom: solid 16px #f7b500;
        top: -5px;
        background-color: $black;
        margin: 0;
        cursor: ew-resize;
      }
      .include {
        position: absolute;
        top: -5px;
        height: 50px;
        border-top: solid 2px #f7b500;
        border-bottom: solid 2px #f7b500;
      }
      .cover {
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        height: 100%;
      }
      .miniLengthLimit {
        position: absolute;
        color: #ff5c00;
        left: 0;
        bottom: -40px;
        width: 100%;
        text-align: center;
      }
    }
  }
}
