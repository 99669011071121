@import "./variables/variables";
@import "./variables/themeMap.scss";

/* 主题 ['light', "dark"] */

// 遍历主题map 取当前主题下的变量；$theme-map就是对应主题的配置
@mixin themeify {
  @each $theme-name, $theme-map in $themes {
    $theme-map: $theme-map !global;
    [data-theme="#{$theme-name}"] & {
      @content;
    }
  }
}

// 声明一个根据Key获取颜色的function
@function themed($key) {
  // map-get 根据对应的key值返回map中对应的值
  // map-get($map, $key)
  @return map-get($theme-map, $key);
}

// 主题背景颜色
@mixin background_color($color) {
  @include themeify {
    background-color: themed($color);
  }
}

// 设置字体颜色
@mixin font_color($color) {
  @include themeify {
    color: themed($color);
  }
}

// 设置边框样式
//$tblr: border方向，参数：border-left,border-top,border-bottom,border-right,border
@mixin border($color: #000000, $width: 1px, $style: solid, $tblr: border) {
  @include themeify {
    #{$tblr}: $width $style themed($color);
  }
}

// 阴影样式
@mixin box_shadow($shadow) {
  @include themeify {
    box-shadow: themed($shadow);
  }
}

// 设置圆角
@mixin border_raduis($raduis) {
  @include themeify {
    border-radius: themed($raduis);
  }
}

// 设置宽度
@mixin width($width) {
  @include themeify {
    width: themed($width);
  }
}

// 设置filter
@mixin filter($filter) {
  @include themeify {
    filter: themed($filter);
  }
}

//设置content
@mixin content($content) {
  @include themeify() {
    content: themed($content);
  }
}
