//通用颜色
$primary-color: #8372ff !default;
$normal-color: #979798 !default;
$white-color-1: #ffffff !default;
$white-color-2: #f7f7fa !default;
$gray-color-2: #5e5f62 !default;
$black: #0f0f10 !default;
$deep-black: #1b1b1b !default;
$dark: #2e2f30 !default;
$deep-dark: #000000 !default;

$deep-gray: #353536 !default;
$gray: #3b3c3d !default;
$low-gray: #5e5f62 !default;

$white: #d8d8d8 !default;
$lightless-white: #e3e3e3 !default;
$light-white: #ffffff !default;
$darkless-white: #a1a1a2 !default;
$darkest-white: #898a8b !default;

$border-gray: #434445 !default;
$border-light: #a1a1a2 !default;

@import "./sass/variables/variables";
@import "./sass/minxin.scss";

.AddMusicWork {
  @include font_color("font-secondary");
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0 0 1rem;
  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }
  .fileList {
    flex: auto;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 4px;
      @include background_color("scrollbar-track-bg");
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      @include background_color("scrollbar-thumb-bg");
    }
    &::-webkit-scrollbar-track {
      border-radius: 4px;
      @include background_color("scrollbar-track-bg");
    }
    .ant-upload.ant-upload-drag .ant-upload {
      padding: 0;
    }
    .recommendFile {
      position: absolute;
      left: 50%;
      top: 120%;
      transform: translateX(-50%);
      border: 1px solid #454649;
      text-align: center;
      width: 120px;
      cursor: pointer;
      div {
        position: relative;
        z-index: 2;
        background-color: $dark;
        padding: 10px;
      }
      p {
        color: #fff;
        margin-bottom: 6px;
      }
      .img-box {
        background-position: center;
        background-size: cover;
        width: 100px;
        height: 70px;
      }
      &::before {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        border-left: solid 10px #454649;
        border-top: solid 12px transparent;
        border-bottom: solid 12px transparent;
        left: 12px;
        top: -12px;
        z-index: -1;
      }
      &::after {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        border-left: solid 10px $dark;
        border-top: solid 12px transparent;
        border-bottom: solid 12px transparent;
        left: 13px;
        top: -10px;
      }
    }
  }
  .close {
    position: absolute;
    border-radius: 100%;
    right: 12px;
    top: 14px;
    border: none;
    padding: 0;
    line-height: 0;
    cursor: pointer;
    background-color: transparent;
    &:focus {
      outline: none;
    }
    &:hover {
      color: $primary-color;
    }
  }
  .workplace {
    & > div {
      margin-bottom: 18px;
      padding: 0 1.5rem;
    }
    p {
      margin-bottom: 0.5rem;
      &.title {
        margin-bottom: 0;
        @include font_color("font-normal");
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 1rem 1.5rem 1rem;
      }
    }
    hr {
      margin: 0 auto 1.5rem;
      border: none;
      @include border("work-hr-color", 1px, solid, border-bottom);
      @include width("work-hr-width");
    }
    .trimAudio {
      @include background_color("bg-hight");
      @include border("work-btn-border");
      @include font_color("font-normal");
      @include border_raduis("work-btn-raduis");
      text-align: center;
      margin: 0 1.5rem 18px;
      padding: 0.2rem 0.75rem 0.2rem 0.6rem;
      border: none;
      cursor: pointer;
      svg {
        width: 24px;
        height: 24px;
        @include font_color("work-spc-btn-color");
      }
    }
    .inputBox {
      input {
        @include background_color("bg-hight");
        @include border("work-btn-border");
        @include border_raduis("work-btn-raduis");
        @include font_color("font-normal");
        width: 100%;
        padding: 7px 1rem;
        font-size: 14px;
        line-height: 18px;
      }
      .inputIcon {
        padding: 0.2rem;
        @include background_color("bg-hight");
        @include border("work-btn-border");
        @include font_color("font-normal");
        @include border_raduis("work-btn-raduis");
        width: 31px;
        height: 31px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
    .ant-slider {
      margin: 0;
    }
  }
  .ant-btn[disabled] {
    @include background_color("work-save-disabled-bg");
    @include border("work-save-disabled-border-color");
    @include font_color("work-save-disabled-color");
  }
  .ant-upload.ant-upload-drag {
    background: transparent;
    @include border("border-normal", 1px, dashed);
    @include border_raduis("raduis-normal");

    width: auto;
    max-width: 100%;
    min-height: 88px;
    margin: 1rem 1.5rem;
    .add {
      text-align: center;
      cursor: pointer;
      padding: 0.75rem 1rem;
      .add-btn {
        @include background_color("work-btn-bg");
        @include font_color("font-normal");
        @include border_raduis("work-btn-raduis");
        @include border("work-addFileBtn-border");

        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0.75rem;
        line-height: 16px;
        svg {
          @include font_color("work-spc-btn-color");
          font-size: 14px;
          margin-right: 0.625rem;
        }
      }
      p {
        @include font_color("font-normal");
        margin: 0;
        font-size: 12px;
      }
    }
  }
  .ant-upload-list {
    display: none;
  }
  .ant-upload.ant-upload-drag p.ant-upload-text {
    color: $white;
    margin-bottom: 1.25rem;
  }
  .css-1j0mc3w-ColorPicker input {
    border: solid 1px $normal-color;
  }
  .save {
    padding: 0 1.5rem;
    button {
      line-height: 1;
      font-weight: bold;
      @include border_raduis("work-save-raduis");
    }
  }
}
