[data-lang="ar"] {
  .Home {
    .Stage .center.open {
      padding-right: 0;
      padding-left: 0;
    }
    .tipsProductModal .ant-modal-body .m-cont .rgt ul,
    .tipsProductModal .ant-modal-body .m-cont .rgt p {
      text-align: right;
    }
    .tipsProductModal .ant-modal-body .m-cont .rgt {
      margin-right: 0;
      margin-left: 0;
    }
    .tipsProductModal .ant-modal-body .m-cont .rgt .ant-btn:first-child {
      margin-left: 38px;
      margin-right: 0;
    }
    .tipsProductModal .ant-modal-body .m-header svg {
      margin-right: 40px;
    }
    .tipsProductModal .ant-modal-body .m-header p {
      padding-right: 1rem !important;
      a {
        margin-left: 0;
        margin-right: 0.5rem;
      }
    }
    .processModal {
      .ant-drawer-content .ant-drawer-body .uploading.guideToDownloadCont .progress .ant-progress .ant-progress-outer {
        margin-right: 0;
        margin-left: calc(-2em - 8px);
      }
      .ant-drawer-content .ant-drawer-body .uploading.guideToDownloadCont .progress .close {
        margin-left: 0;
        margin-right: 1rem;
      }
    }
  }
  .CutWork {
    .item .img-box {
      margin-right: 0;
      margin-left: 0.75rem;
    }
    .add .anticon {
      margin-right: 0;
      margin-left: 0.5rem;
    }
  }
  .ResizeWork {
    .setFill i,
    .setFit i {
      margin-right: 0;
      margin-left: 10px;
    }
    .color-picker .more-color > span {
      margin: 0 0 0 6px;
    }
  }
  .MergeWork {
    .name {
      padding-left: 0;
      padding-right: 0.2rem;
    }
    .info {
      padding-right: 0.5rem;
      padding-left: 0;
    }
    .footer .color-picker .color-picker-form > span {
      margin-right: 0;
      margin-left: 6px;
    }
  }
  .SubtitleWork {
    .color-picker .color-input {
      margin-right: 0;
      margin-left: 0.5rem;
    }
    .add .anticon {
      margin-left: 0.5rem;
      margin-right: 0;
    }
  }
  .RemoveWatermarkWork {
    .add svg {
      margin-right: 0;
      margin-left: 10px;
    }
  }
  .AddWatermarkWork {
    .add .anticon {
      margin-left: 0.5rem;
      margin-right: 0;
    }
    .color-picker .color-input {
      margin-left: 0.5rem;
      margin-right: 0;
      & > span {
        margin-left: 6px;
        margin-right: 0;
      }
    }
  }
  .AddMusicWork {
    .close {
      right: auto;
      left: 12px;
    }
  }
  .Preview {
    .controls .ant-breadcrumb {
      margin-left: 0;
      margin-right: 0.875rem;
    }
    .controls .screenshot-icon {
      margin-left: 0;
      margin-right: 0.4rem;
    }
    .VideoPreview {
      .operation .operation-item:first-child {
        border-left: 1px solid #434445;
        border-right: 0;
      }
      .operation .operation-item svg {
        margin-right: 0;
        margin-left: 0.5rem;
      }
    }
  }
  .Upload {
    .AddFileByURL .ant-input-affix-wrapper svg {
      margin-left: 0.6rem;
      margin-right: 0;
    }
    .AddFileByURL .ant-input-group-addon button {
      border-right: none;
      border-left: 1px solid #434445;
      svg {
        transform: rotate(180deg);
      }
    }
  }

  @media (min-width: 768px) {
    .Home {
      .tipsProductModal .ant-modal-body .m-cont .rgt {
        margin-right: 60px;
      }
    }
  }

  @media (min-width: 992px) {
    .Home .Stage {
      .center.open {
        padding-left: 300px;
      }
      .ant-drawer.ant-drawer-open {
        width: 300px !important;
      }
      .right {
        flex: 0 0 300px;
        width: 300px;
      }
    }
    .Preview {
      .controls .screenshot-icon {
        margin-right: 1.5rem;
      }
    }
  }
}

@primary-color: #8372FF;@normal-color: #979798;