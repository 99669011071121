//通用颜色
$primary-color: #8372ff !default;
$normal-color: #979798 !default;
$white-color-1: #ffffff !default;
$white-color-2: #f7f7fa !default;
$gray-color-2: #5e5f62 !default;
$black: #0f0f10 !default;
$deep-black: #1b1b1b !default;
$dark: #2e2f30 !default;
$deep-dark: #000000 !default;

$deep-gray: #353536 !default;
$gray: #3b3c3d !default;
$low-gray: #5e5f62 !default;

$white: #d8d8d8 !default;
$lightless-white: #e3e3e3 !default;
$light-white: #ffffff !default;
$darkless-white: #a1a1a2 !default;
$darkest-white: #898a8b !default;

$border-gray: #434445 !default;
$border-light: #a1a1a2 !default;

@import "./sass/variables/variables";
@import "./sass/minxin.scss";

.AddWatermarkWork {
  @include font_color("font-normal");
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 1.5rem 0 1rem;
  & > .ant-tabs > .ant-tabs-content-holder > .ant-tabs-content {
    height: 100%;
  }
  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;

    button {
      background-color: $gray;
      border-color: $gray;
      color: $white;
    }

    .ant-radio-wrapper {
      color: $normal-color !important;
    }
  }

  input,
  textarea {
    padding: 0;
    margin: 0;
    @include font_color("font-normal");
    @include background_color("work-input-bg");
    border: none;
    font-size: 14px;
    padding: 2px 0.3rem;
    &::-webkit-scrollbar {
      width: 4px;
      @include background_color("scrollbar-track-bg");
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      @include background_color("scrollbar-thumb-bg");
    }

    &::-webkit-scrollbar-track {
      border-radius: 4px;
      @include background_color("scrollbar-track-bg");
    }
  }

  .ant-tabs-content-holder .list {
    overflow-y: auto;
    padding-bottom: 1rem;
    &::-webkit-scrollbar {
      width: 4px;
      @include background_color("scrollbar-track-bg");
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      @include background_color("scrollbar-thumb-bg");
    }

    &::-webkit-scrollbar-track {
      border-radius: 4px;
      @include background_color("scrollbar-track-bg");
    }
  }

  & > .ant-tabs {
    & > .ant-tabs-nav,
    & > div > .ant-tabs-nav {
      margin-bottom: 1.5rem;
      padding: 0 1.5rem;
      color: $darkest-white;
    }
  }
  .add {
    @include font_color("font-normal");
    font-size: 14px;
    padding: 0 1.5rem;
    text-align: center;
    margin-bottom: 1rem;

    button {
      @include background_color("work-btn-bg");
      @include font_color("font-normal");
      @include border("work-btn-border");
      @include border_raduis("work-btn-raduis");
      width: 100%;
      text-align: left;
      padding: 4px 1.5rem;
      border: none;
      cursor: not-allowed;
      text-align: center;
      &[disabled] {
        opacity: 0.5;
      }

      &:not([disabled]) {
        cursor: pointer;
      }

      &:not([disabled]):hover {
        color: $primary-color;
      }
    }

    button:focus {
      outline: none;
    }

    .anticon {
      padding: 4px;
      margin-right: 0.5rem;
      @include font_color("work-spc-btn-color");
    }
  }

  .subtitle-content {
    filter: opacity(0.8);
    @include background_color("work-btn-bg");
    @include font_color("font-secondary");
    @include border_raduis("work-btn-raduis");
    border: 1px solid transparent;
    position: relative;
    cursor: pointer;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    padding: 1.25rem 1rem 1rem;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    &.active,
    &:hover {
      filter: opacity(1);
      border-color: $primary-color;
      box-shadow: 0px 0px 6px rgba(131, 114, 255, 0.4);
    }

    &:hover {
      .close {
        display: block;
      }
    }

    .time-row {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      span {
        margin-right: 0.5rem;
        flex-shrink: 0;
      }
      .time-cont {
        display: flex;
        align-items: center;
        @include font_color("font-normal");
        @include background_color("work-input-bg");
        border-radius: 4px;
        overflow: hidden;
        width: 100%;
        input {
          text-align: center;
        }
      }
    }

    textarea {
      height: 66px;
      margin-bottom: 1rem;
      border-radius: 4px;
      padding: 7px 0.75rem;
      border: none;
    }

    .close {
      display: none;
      position: absolute;
      background-color: transparent;
      right: 0;
      top: 0;
      border: none;
      padding: 0;
      line-height: 0;
      cursor: pointer;
      @include font_color("font-normal");
      &:focus {
        outline: none;
      }

      &:hover {
        color: $primary-color;
      }
    }
  }

  .color-picker {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .color-input {
      display: flex;
      align-items: center;
      margin-right: 0.5rem;
      @include background_color("work-input-bg");
      border-radius: 4px;
      width: 96px;
      padding: 6px 7px;
      .color-value {
        border: 0;
        margin: 0;
        text-transform: lowercase;
        @include font_color("font-normal");
      }
      & > span {
        border: none;
        padding: 0;
        width: auto;
        border-radius: 0;
        background-color: transparent;
        height: auto;
        margin-right: 6px;
      }

      & > span.active {
        border: solid 4px $gray;
        background-color: $gray;
      }

      & > span > span {
        height: 18px;
        width: 18px;
        padding-left: 0;
        color: $white;
        background-image: url("./images/blur.png");
        background-size: contain;
        background-repeat: no-repeat;
        margin: 0;
        background-color: inherit !important;
      }
    }
  }

  .presetColor {
    margin-left: 0.2rem;
    background-color: transparent;
    padding: 0px;
    position: relative;
    cursor: pointer;
    div {
      width: 18px;
      height: 18px;
      border-radius: 100%;
      background-size: contain;
      border: 1px solid rgba(27, 27, 27, 0.08);
      &.colorNone {
        background: url('data:image/svg+xml,<svg width="18" height="18" xmlns="http://www.w3.org/2000/svg"><path d="M9 0c2.304 0 4.406.866 5.998 2.29l.01-.01.707.707-.009.01A9 9 0 012.997 15.706l-.01.009-.707-.707.01-.01A9 9 0 019 0zm5.998 3.706L3.706 14.998A8 8 0 0014.998 3.706zM9 1a8 8 0 00-6.002 13.29L14.289 2.997A7.97 7.97 0 009 1z" fill="%235E5F62" fill-rule="nonzero"/></svg>')
          no-repeat center center/100%;
      }
    }
    &.active::before {
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      border: 1px solid $border-light;
      top: -3px;
      left: -3px;
      border-radius: 50%;
    }
  }

  .color-picker-cont {
    // background-color: $dark;
    @include background_color("bg-hight");
    margin-top: 1rem;
    border-radius: 4px;
    padding: 0.625rem 0 1rem;
    &.ant-tabs > .ant-tabs-nav,
    &.ant-tabs > div > .ant-tabs-nav {
      margin-bottom: 1rem;
      @include font_color("font-normal");
    }
    .ant-tabs-content-holder {
      padding: 0 1rem;
      overflow: hidden;
    }
    .ant-tabs-tab-btn {
      font-size: 12px;
    }
    .ant-tabs-tab {
      border-bottom-width: 1px !important;
    }
  }

  hr {
    margin: 20px auto 1.5rem auto;
    border: none;
    @include border("work-hr-color", 1px, solid, border-bottom);
    @include width("work-hr-width");
  }
  .ant-slider {
    margin: 0;
  }
  .subtitle-list {
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
      @include background_color("scrollbar-track-bg");
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      @include background_color("scrollbar-thumb-bg");
    }

    &::-webkit-scrollbar-track {
      border-radius: 4px;
      @include background_color("scrollbar-track-bg");
    }
    .itemlist {
      padding: 0 1.5rem 1.5rem;
      .item {
        filter: opacity(0.8);
        @include background_color("work-btn-bg");
        @include font_color("font-secondary");
        @include border_raduis("work-btn-raduis");
        padding: 1rem;
        cursor: pointer;
        position: relative;
        border: solid 1px transparent;
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
        &:hover,
        &.active {
          filter: opacity(1);
          border: solid 1px $primary-color;
          box-shadow: 0px 0px 6px rgba(131, 114, 255, 0.4);
        }
        &:hover {
          .close {
            display: block;
          }
        }
        .item-info {
          display: flex;
          align-items: center;
          .img-box {
            background-position: center;
            background-size: cover;
            height: 32px;
            width: 50px;
            flex-shrink: 0;
            margin-right: 0.75rem;
          }
          .item-time-cont {
            display: flex;
            align-items: center;
            margin-top: 10px;
            @include background_color("work-input-bg");
            border-radius: 4px;
            overflow: hidden;
          }
        }
        input {
          padding: 0;
          margin: 0;
          @include font_color("font-normal");
          font-size: 12px;
          padding: 4px 0;
          text-align: center;
          &:last-of-type {
            padding: 4px 0;
          }
        }
        .close {
          display: none;
          position: absolute;
          background-color: transparent;
          right: 0;
          top: 0;
          border: none;
          padding: 0;
          line-height: 0;
          cursor: pointer;
          @include font_color("font-normal");
          &:focus {
            outline: none;
          }
          &:hover {
            color: $primary-color;
          }
        }
      }
    }
  }

  .ant-upload-list {
    display: none;
  }
  .ant-upload {
    width: 100%;
  }
  .ant-select {
    @include font_color("font-normal");
    font-size: 14px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
  }
  .ant-select-arrow {
    @include font_color("select-arrow-icon-color");
    top: calc(50% + 2px);
    svg {
      width: 10px;
      height: 10px;
    }
  }
  .ant-picker {
    padding: 0;
    border-radius: 0;
    border: none;
    background-color: transparent;
    width: 100%;
  }
  .ant-picker-focused {
    box-shadow: none;
  }
  .save button {
    font-weight: bold;
    line-height: 1;
    @include border_raduis("work-save-raduis");
    &[disabled] {
      opacity: 0.5;
      @include background_color("work-save-disabled-bg");
      @include border("work-save-disabled-border-color");
      @include font_color("work-save-disabled-color");
    }
  }
}
