//通用颜色
$primary-color: #8372ff !default;
$normal-color: #979798 !default;
$white-color-1: #ffffff !default;
$white-color-2: #f7f7fa !default;
$gray-color-2: #5e5f62 !default;
$black: #0f0f10 !default;
$deep-black: #1b1b1b !default;
$dark: #2e2f30 !default;
$deep-dark: #000000 !default;

$deep-gray: #353536 !default;
$gray: #3b3c3d !default;
$low-gray: #5e5f62 !default;

$white: #d8d8d8 !default;
$lightless-white: #e3e3e3 !default;
$light-white: #ffffff !default;
$darkless-white: #a1a1a2 !default;
$darkest-white: #898a8b !default;

$border-gray: #434445 !default;
$border-light: #a1a1a2 !default;

@import "./sass/variables/variables";
@import "./sass/minxin.scss";

.ResizeWork {
  @include font_color("font-normal");
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 2rem 0 1.5rem;
  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
    button {
      @include background_color("work-save-disabled-bg");
      @include border("work-save-disabled-border-color");
      @include font_color("work-save-disabled-color");
    }
    .setResolutionBtn {
      color: $normal-color !important;
    }
    .setFit,
    .setFill {
      color: $normal-color !important;
      i {
        border-color: $normal-color !important;
        &::before {
          background-color: $normal-color !important;
        }
      }
    }
  }
  .operation-cont {
    flex: 2;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 4px;
      @include background_color("scrollbar-track-bg");
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      @include background_color("scrollbar-thumb-bg");
    }
    &::-webkit-scrollbar-track {
      border-radius: 4px;
      @include background_color("scrollbar-track-bg");
    }
    .tips {
      margin-bottom: 24px;
      @include font_color("font-secondary");
      text-align: center;
    }
  }
  .ant-btn[disabled] {
    background-color: $gray;
    color: $normal-color;
    border: none;
  }
  button {
    line-height: 1;
    font-weight: bold;
  }
  button:not(:first-child) {
    margin-top: 0.5rem;
  }
  .setResolutionBtn {
    @include background_color("work-btn-bg");
    @include font_color("font-normal");
    @include border("work-btn-border");
    @include border_raduis("work-btn-raduis");
    height: 32px;
    cursor: pointer;
    text-align: center;
    line-height: 32px;
    margin: 0 5px;
    &:hover,
    &.active {
      color: $primary-color;
      border-color: $primary-color;
      box-shadow: 0px 0px 6px rgba(131, 114, 255, 0.4);
    }
  }
  .setFit {
    @include background_color("work-btn-bg");
    @include font_color("font-normal");
    @include border("work-btn-border");
    @include border_raduis("work-btn-raduis");
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 0;
    cursor: pointer;
    &.active {
      i {
        border-color: $primary-color;
        &::before {
          background-color: $primary-color;
        }
      }
    }
    i {
      width: 24px;
      height: 16px;
      border: 1px solid #9f9fa1;
      display: block;
      margin-right: 10px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 8px;
        height: 12px;
        background-color: #9f9fa1;
        left: 7px;
        top: 1px;
      }
    }
  }
  .setFill {
    @include background_color("work-btn-bg");
    @include font_color("font-normal");
    @include border("work-btn-border");
    @include border_raduis("work-btn-raduis");
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 0;
    cursor: pointer;
    &.active {
      i {
        border-color: $primary-color;
        &::before {
          background-color: $primary-color;
        }
      }
    }
    i {
      width: 24px;
      height: 16px;
      border: 1px solid #9f9fa1;
      display: block;
      margin-right: 10px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 20px;
        height: 12px;
        background-color: #9f9fa1;
        left: 1px;
        top: 1px;
      }
    }
  }
  hr {
    border: none;
    @include border("work-hr-color", 1px, solid, border-bottom);
    @include width("work-hr-width");
    margin: 1.5rem auto 1.5rem;
  }
  .color-picker {
    display: flex;
    align-items: center;
    margin: 1rem 0;
    justify-content: space-between;
    & > span {
      border: solid 6px transparent;
      padding: 0;
      width: auto;
      border-radius: 0;
      background-color: transparent;
      height: auto;
    }
    & > span.active {
      border: solid 6px $gray;
      background-color: $gray;
    }
    .more-color {
      display: flex;
      align-items: center;
      @include background_color("work-btn-bg");
      @include font_color("font-normal");
      padding: 6px 7px;
      border-radius: 4px;
      width: 96px;
      & > span {
        height: 19px;
        width: 19px;
        padding: 0;
        margin: 0 6px 0 0;
        background: transparent;
        border: none;
        & > span {
          height: 19px;
          width: 19px;
          color: $white;
          background-image: url("./images/blur.png");
          background-size: contain;
          background-repeat: no-repeat;
          margin: 0;
          background-color: inherit !important;
        }
      }
    }
  }
  .presetColor {
    margin-left: 0.2rem;
    background-color: transparent;
    width: 19px;
    height: 19px;
    cursor: pointer;
    position: relative;
    div {
      width: 100%;
      height: 100%;
      background-size: cover !important;
      overflow: hidden;
      border-radius: 50%;
    }
    &.active::before {
      content: "";
      width: 25px;
      height: 25px;
      display: block;
      border: 1px solid $border-light;
      border-radius: 50%;
      position: absolute;
      top: -3px;
      left: -3px;
    }
  }

  .video-bg-list {
    .ant-col {
      border: 1px solid transparent;
      &.active {
        @include border_raduis("work-resize-bgPic-active-raduis");
        border-color: $primary-color;
        // filter: drop-shadow(0px 0px 6px rgba(131, 114, 255, 0.4));
      }
    }
    .item {
      @include border_raduis("raduis-normal");
      height: 60px;
      cursor: pointer;
    }
    .bg1 {
      background: url(./images/bg1.png) no-repeat center center/cover;
    }
    .bg2 {
      background: url(./images/bg2.png) no-repeat center center/cover;
    }
    .bg3 {
      background: url(./images/bg3.png) no-repeat center center/cover;
    }
    .bg4 {
      background: url(./images/bg4.png) no-repeat center center/cover;
    }
    .bg5 {
      background: url(./images/bg5.png) no-repeat center center/cover;
    }
    .bg6 {
      background: url(./images/bg6.png) no-repeat center center/cover;
    }
    .bg7 {
      background: url(./images/bg7.png) no-repeat center center/cover;
    }
    .bg8 {
      background: url(./images/bg8.png) no-repeat center center/cover;
    }
    .bg9 {
      background: url(./images/bg9.png) no-repeat center center/cover;
    }
    .bg10 {
      background: url(./images/bg10.png) no-repeat center center/cover;
    }
    .bg11 {
      background: url(./images/bg11.png) no-repeat center center/cover;
    }
    .bg12 {
      background: url(./images/bg12.png) no-repeat center center/cover;
    }
    .bg13 {
      background: url(./images/bg13.png) no-repeat center center/cover;
    }
    .bg14 {
      background: url(./images/bg14.png) no-repeat center center/cover;
    }
    .bg15 {
      background: url(./images/bg15.png) no-repeat center center/cover;
    }
  }

  .save {
    padding: 1.5rem 1.5rem 0;
    button {
      @include border_raduis("work-save-raduis");
      font-size: 16px;
    }
  }
}
