.wf-active body {
	font-family: 'Mulish';
}

#hitpaw-root {
	width: 100%;
	height: 100%;
}

[data-sys="mac"] .sys-win {
	display: none !important;
}
[data-dev="mobile"] .dev-desktop {
	display: none !important;
}

[data-lang="ar"]{
	direction: rtl;
}