//通用颜色
$primary-color: #8372ff !default;
$normal-color: #979798 !default;
$white-color-1: #ffffff !default;
$white-color-2: #f7f7fa !default;
$gray-color-2: #5e5f62 !default;
$black: #0f0f10 !default;
$deep-black: #1b1b1b !default;
$dark: #2e2f30 !default;
$deep-dark: #000000 !default;

$deep-gray: #353536 !default;
$gray: #3b3c3d !default;
$low-gray: #5e5f62 !default;

$white: #d8d8d8 !default;
$lightless-white: #e3e3e3 !default;
$light-white: #ffffff !default;
$darkless-white: #a1a1a2 !default;
$darkest-white: #898a8b !default;

$border-gray: #434445 !default;
$border-light: #a1a1a2 !default;

@import "./sass/variables/variables";
@import "./sass/minxin.scss";

.MuteWork {
  color: $white;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 1.25rem;
  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
    button {
      background-color: $gray;
      border-color: $gray;
      color: $white;
    }
    .ant-radio-wrapper {
      color: $normal-color !important;
    }
  }
  .ant-btn[disabled] {
    background-color: $gray;
    color: $normal-color;
    border: none;
  }
  button {
    font-size: 18px;
    line-height: 1;
  }
  button:not(:first-child) {
    margin-top: 0.5rem;
  }
  .ant-radio-wrapper {
    color: $white;
    display: block;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    .ant-radio-inner {
      background-color: transparent !important;
      border-color: $border-gray !important;
    }
  }
  .ant-col {
    display: none;
    &.active {
      display: block;
    }
    .muteFileBox {
      height: 32px;
      background: #313233;
      border: 1px solid #454649;
      text-align: center;
      line-height: 32px;
      font-size: 14px;
      margin: 0 5px;
      color: $white;
    }
  }
  hr {
    border-color: #000;
    margin: 20px 5px 25px 5px;
  }
}
