//通用颜色
$primary-color: #8372ff !default;
$normal-color: #979798 !default;
$white-color-1: #ffffff !default;
$white-color-2: #f7f7fa !default;
$gray-color-2: #5e5f62 !default;
$black: #0f0f10 !default;
$deep-black: #1b1b1b !default;
$dark: #2e2f30 !default;
$deep-dark: #000000 !default;

$deep-gray: #353536 !default;
$gray: #3b3c3d !default;
$low-gray: #5e5f62 !default;

$white: #d8d8d8 !default;
$lightless-white: #e3e3e3 !default;
$light-white: #ffffff !default;
$darkless-white: #a1a1a2 !default;
$darkest-white: #898a8b !default;

$border-gray: #434445 !default;
$border-light: #a1a1a2 !default;

@import "./sass/variables/variables";
@import "./sass/minxin.scss";

.Upload {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  .AddFile {
    width: 100%;
    height: 100%;
  }
  .AddFileByURL {
    border: none !important;
    padding: 0 !important;
    max-width: 100%;
    .ant-input-wrapper {
      display: flex;
      align-items: stretch;
      margin: 1.4rem 0 0 !important;
    }
    .ant-input-affix-wrapper {
      @include border_raduis("raduis-normal");
      @include border("border-normal", 1px);
      border-right: none !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      margin-top: 0 !important;
      color: #e3e3e3;
      font-size: 12px;
      padding: 8px 1.2rem;
      background-color: transparent;
      margin-top: 10px;
      border-radius: 0;
      width: 552px;
      max-width: 100%;
      input {
        background-color: transparent;
        // color: $normal-color;
        @include font_color("font-normal");
      }
      svg {
        margin-right: 0.6rem;
      }
    }
    .ant-input-group-addon {
      width: 48px;
      border: none;
      background: transparent;
      button {
        @include border("border-normal");
        @include border_raduis("raduis-normal");
        @include font_color("upload-url-icon-color");
        background: transparent;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: none;
        &:hover {
          @include font_color("upload-url-icon-hover-color");
        }
      }
    }
    .tips {
      @include font_color("font-normal");
      text-decoration-line: underline;
      width: fit-content;
      margin: 1.2rem auto 0;
      &:hover {
        color: $primary-color;
      }
    }
  }
  .uploading {
    color: $normal-color;
    text-align: center;
    font-size: 1.2rem;
    .timeIcon {
      margin: auto;
      display: block;
      width: 60px;
      height: 60px;
      background: url(./images/timg.svg) no-repeat center center/100%;
    }
    .progress {
      display: flex;
      align-items: center;
      margin-top: 1rem;
      .close {
        cursor: pointer;
        margin-left: 0.5rem;
        font-size: 14px;
      }
    }
  }
  .ant-upload.ant-upload-drag {
    background: transparent;
    border: none;
    span.ant-upload {
      padding: 0;
      height: 100%;
      display: block;
    }
    .ant-upload-drag-container {
      display: block;
      height: 100%;
    }
  }
  .ant-upload-drag-hover .addFile-box > div {
    border: 1px dashed $primary-color;
  }
  .ant-upload-list {
    display: none;
  }
  .ant-upload.ant-upload-drag p.ant-upload-text {
    @include font_color("font-normal");
    margin-bottom: 1.25rem;
  }
  .ant-progress-text {
    color: $white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-14px, -6px);
  }
  .ant-progress-outer {
    min-width: 400px;
    margin: 0;
    padding: 0;
    line-height: 14px;
    .ant-progress-inner {
      background-color: #454649;
      height: 14px;
      .ant-progress-bg {
        height: 100% !important;
      }
    }
  }
  .addFile-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & > div {
      @include border_raduis("raduis-normal");
      @include border("border-normal", 1px, dashed);
      width: 600px;
      max-width: 100%;
      padding: 7.3rem 4rem;
      .ant-btn-lg {
        min-width: 180px;
        min-height: 48px;
        font-weight: bold;
        font-size: 18px;
        line-height: 23px;
        @include border_raduis("raduis-normal");
      }
    }
  }
  @media (min-width: 992px) {
    padding: 0px;
  }
}
