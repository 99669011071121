//通用颜色
$primary-color: #8372ff !default;
$normal-color: #979798 !default;
$white-color-1: #ffffff !default;
$white-color-2: #f7f7fa !default;
$gray-color-2: #5e5f62 !default;
$black: #0f0f10 !default;
$deep-black: #1b1b1b !default;
$dark: #2e2f30 !default;
$deep-dark: #000000 !default;

$deep-gray: #353536 !default;
$gray: #3b3c3d !default;
$low-gray: #5e5f62 !default;

$white: #d8d8d8 !default;
$lightless-white: #e3e3e3 !default;
$light-white: #ffffff !default;
$darkless-white: #a1a1a2 !default;
$darkest-white: #898a8b !default;

$border-gray: #434445 !default;
$border-light: #a1a1a2 !default;

@import "./sass/variables/variables";
@import "./sass/minxin.scss";

.SubtitleWork {
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 1.5rem 0;
  font-size: 14px;
  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;

    button {
      @include background_color("work-save-disabled-bg");
      @include border("work-save-disabled-border-color");
      @include font_color("work-save-disabled-color");
    }

    .ant-radio-wrapper {
      color: $normal-color !important;
    }
  }
  & > .ant-tabs {
    & > .ant-tabs-nav,
    & > div > .ant-tabs-nav {
      margin-bottom: 1.5rem;
      padding: 0 1.5rem;
      color: $darkest-white;
    }
    .ant-tabs-content-holder {
      padding: 0 1.5rem;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 4px;
        @include background_color("scrollbar-track-bg");
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        @include background_color("scrollbar-thumb-bg");
      }

      &::-webkit-scrollbar-track {
        border-radius: 4px;
        @include background_color("scrollbar-track-bg");
      }
    }
  }
  textarea {
    padding: 0;
    margin: 0;
    color: $white;
    font-size: 14px;
    background-color: $gray;
    padding: 2px 0.5rem;
    border-radius: 0;
    border-color: transparent;
    &::-webkit-scrollbar {
      width: 4px;
      @include background_color("scrollbar-track-bg");
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      @include background_color("scrollbar-thumb-bg");
    }

    &::-webkit-scrollbar-track {
      border-radius: 4px;
      @include background_color("scrollbar-track-bg");
    }
  }

  .add {
    @include font_color("font-normal");
    font-size: 14px;
    text-align: center;
    margin-bottom: 1rem;

    button {
      @include background_color("work-btn-bg");
      @include font_color("font-normal");
      @include border("work-btn-border");
      @include border_raduis("work-btn-raduis");
      width: 100%;
      text-align: left;
      padding: 4px 1.5rem;
      border: none;
      cursor: not-allowed;
      border-radius: 4px;
      text-align: center;
      &[disabled] {
        opacity: 0.5;
      }

      &:not([disabled]) {
        cursor: pointer;
      }

      &:not([disabled]):hover {
        color: $primary-color;
      }
    }

    button:focus {
      outline: none;
    }

    .anticon {
      padding: 4px;
      margin-right: 0.5rem;
      @include font_color("work-spc-btn-color");
    }
  }

  .subtitle-content {
    filter: opacity(0.8);
    @include background_color("work-btn-bg");
    @include font_color("font-secondary");
    @include border_raduis("work-btn-raduis");
    border: 1px solid transparent;
    position: relative;
    cursor: pointer;
    border-radius: 4px;
    margin-bottom: 1rem;
    padding: 1.25rem 1rem 1rem;
    &:hover,
    &.active {
      filter: opacity(1);
      border-color: $primary-color;
      box-shadow: 0px 0px 6px rgba(131, 114, 255, 0.4);
    }

    &:hover {
      .close {
        display: block;
      }
    }

    .time-row {
      @include background_color("work-input-bg");
      border-radius: 4px;
      overflow: hidden;
      height: 24px;
      display: flex;
      align-items: center;
      width: 100%;
      .ant-input,
      .ant-input:focus,
      .ant-input:active {
        border: none;
        box-shadow: none;
        text-align: center;
        padding: 2px 0.1rem;
        @include background_color("work-input-bg");
        @include font_color("font-normal");
      }
      span {
        margin-right: 0.2rem;
        flex-shrink: 0;
      }
    }

    textarea {
      height: 64px;
      resize: none;
      margin-bottom: 1rem;
      border-radius: 4px;
      @include font_color("font-normal");
      @include background_color("work-input-bg");
    }

    .close {
      display: none;
      position: absolute;
      background-color: transparent;
      right: 0rem;
      top: 0rem;
      border: none;
      padding: 0;
      line-height: 0;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      &:hover {
        color: $primary-color;
      }
    }
  }

  .color-picker-cont {
    @include background_color("bg-hight");
    margin-top: 1rem;
    border-radius: 4px;
    padding: 0.625rem 0 1rem;
    &.ant-tabs > .ant-tabs-nav,
    &.ant-tabs > div > .ant-tabs-nav {
      margin-bottom: 1rem;
      @include font_color("font-normal");
    }
    .ant-tabs-content-holder {
      padding: 0 1rem;
      overflow: hidden;
    }
    .ant-tabs-tab-btn {
      font-size: 12px;
    }
    .ant-tabs-tab {
      border-bottom-width: 1px !important;
    }
  }

  .color-picker {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .color-input {
      display: flex;
      align-items: center;
      margin-right: 0.5rem;
      @include background_color("work-input-bg");
      padding: 6px 7px;
      border-radius: 4px;
      width: 96px;
      flex-shrink: 0;
      .color-value {
        border: 0;
        text-transform: lowercase;
        @include font_color("font-normal");
      }
      & > span {
        border: none;
        padding: 0;
        width: auto;
        border-radius: 0;
        background-color: transparent;
        height: auto;
        margin-right: 6px;
      }

      & > span.active {
        border: solid 4px $gray;
        background-color: $gray;
      }

      & > span > span {
        height: 18px;
        width: 18px;
        padding-left: 0;
        color: $white;
        background-image: url("./images/blur.png");
        background-size: contain;
        background-repeat: no-repeat;
        margin: 0;
        background-color: inherit !important;
      }
    }
  }

  .presetColor {
    margin-left: 0.2rem;
    background-color: transparent;
    padding: 0px;
    position: relative;
    cursor: pointer;
    div {
      width: 18px;
      height: 18px;
      border-radius: 100%;
      background-size: contain;
      border: 1px solid rgba(27, 27, 27, 0.08);
      &.colorNone {
        background: url('data:image/svg+xml,<svg width="18" height="18" xmlns="http://www.w3.org/2000/svg"><path d="M9 0c2.304 0 4.406.866 5.998 2.29l.01-.01.707.707-.009.01A9 9 0 012.997 15.706l-.01.009-.707-.707.01-.01A9 9 0 019 0zm5.998 3.706L3.706 14.998A8 8 0 0014.998 3.706zM9 1a8 8 0 00-6.002 13.29L14.289 2.997A7.97 7.97 0 009 1z" fill="%235E5F62" fill-rule="nonzero"/></svg>')
          no-repeat center center/100%;
      }
    }
    &.active::before {
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      border: 1px solid $border-light;
      top: -3px;
      left: -3px;
      border-radius: 50%;
    }
  }

  .position {
    width: 90px;

    .ant-col {
      & > div {
        @include background_color("work-subtitle-position-bg");
        height: 24px;
        cursor: pointer;
        border-radius: 2px;
        &:hover {
          background: $primary-color;
        }
      }
    }
  }

  .ant-select-arrow {
    @include font_color("select-arrow-icon-color");
    top: calc(50% + 2px);
    svg {
      width: 10px;
      height: 10px;
    }
  }

  .save button {
    line-height: 1;
    font-weight: bold;
    @include border_raduis("work-save-raduis");
  }
}
