//通用颜色
$primary-color: #8372ff !default;
$normal-color: #979798 !default;
$white-color-1: #ffffff !default;
$white-color-2: #f7f7fa !default;
$gray-color-2: #5e5f62 !default;
$black: #0f0f10 !default;
$deep-black: #1b1b1b !default;
$dark: #2e2f30 !default;
$deep-dark: #000000 !default;

$deep-gray: #353536 !default;
$gray: #3b3c3d !default;
$low-gray: #5e5f62 !default;

$white: #d8d8d8 !default;
$lightless-white: #e3e3e3 !default;
$light-white: #ffffff !default;
$darkless-white: #a1a1a2 !default;
$darkest-white: #898a8b !default;

$border-gray: #434445 !default;
$border-light: #a1a1a2 !default;

@import "./sass/variables/variables";
@import "./sass/minxin.scss";

.Preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 346px;
  height: auto;
  padding-top: 32px;
  @media (min-width: 992px) {
    min-height: auto;
    height: 100%;
    padding-top: 0;
  }
  .videoWrap {
    width: 100%;
    position: relative;
    text-align: center;
    .videoWrap-box {
      @include border_raduis("preview-video-cont-raduis");
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      overflow: hidden;
    }
    .mergeVideoWrap,
    .resizeVideoWrap {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: -1;
      &.active {
        z-index: 2;
      }
      video {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .resizeVideoWrap {
      video {
        border-radius: 0 !important;
      }
    }
    .mergeVideoWrap {
      video {
        border-radius: 0 !important;
        border: none !important;
      }
    }
    video {
      display: block;
      margin: auto;
      width: 100%;
      height: auto;
      z-index: 2;
      @include border_raduis("preview-video-cont-raduis");
      @include border("preview-video-border");
      border-bottom: 0;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      overflow: hidden;
    }
  }
  & > img {
    flex: 1 1 auto;
    width: 100%;
    max-height: 333px;
    height: 100%;
  }

  .controls {
    @include background_color("preview-track-bg");
    @include font_color("font-normal");
    @include border_raduis("preview-video-cont-raduis");
    @include border("preview-video-border");
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 2;
    padding: 0.2rem 6px;
    margin: 0 auto 0;
    .controls-rgt {
      display: flex;
      align-items: center;
    }
    .anticon-sound {
      width: 1.2rem;
      height: 1.2rem;
      cursor: pointer;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    .controls-lft {
      svg {
        width: 40px;
        height: 40px;
        display: block;
        cursor: pointer;
      }
    }
    .ant-breadcrumb {
      margin-left: 0.875rem;
    }
    .ant-breadcrumb,
    .ant-breadcrumb > span:last-child,
    .ant-breadcrumb-separator {
      @include font_color("font-secondary");
      font-size: 12px;
      .current-time {
        @include font_color("font-normal");
      }
    }

    .screenshot-icon {
      cursor: pointer;
      margin-left: 0.4rem;
      @include font_color("font-secondary");
    }

    .frame-track {
      flex: 2;
      .ant-slider.normal.play-track {
        .ant-slider-rail {
          height: 4px;
        }
        .ant-slider-track {
          height: 4px;
        }
        .ant-slider-step {
          height: 4px;
        }
        .ant-slider-handle {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          top: 5px;
          &::after {
            border: none;
          }
        }
      }
      &.frame-merge {
        display: flex;
        width: 100%;
        height: 100%;
        padding: 0.5rem 0;
        display: flex;
        justify-content: space-between;
        .ant-slider {
          margin-right: 0;
          margin-left: 0;
        }
        .ant-slider-track {
          // background-color: $border-gray;
          @include background_color("perview-merge-track-bg");
        }
        .active .ant-slider-track {
          background-color: $primary-color;
        }
        .ant-slider-handle {
          display: none;
        }
        .active .ant-slider-handle {
          display: block;
        }
      }
    }
    @media (min-width: 992px) {
      padding: 6px 30px 6px 16px;
      .screenshot-icon {
        margin-left: 1.5rem;
      }
    }
  }
  .soundControl {
    width: 110px;
    position: relative;
    font-size: 12px;
    @media (min-width: 992px) {
      width: 130px;
      font-size: 14px;
    }
    .ant-slider {
      width: 80px;
      margin: 0;
      position: absolute;
      top: 0.2rem;
      right: 0;
      @media (min-width: 992px) {
        width: 100px;
      }
      .ant-slider-track {
        background-color: $primary-color;
      }
    }
  }
  .track {
    position: relative;
    margin: 2.75rem auto 0.5rem;
    height: 40px;
    .screenshots {
      display: flex;
      @include border_raduis("raduis-normal");
      overflow: hidden;
      .screenshot {
        width: 100%;
        height: 40px;
        background-image: url("./images/timg.svg");
        background-size: 40%;
        background-repeat: no-repeat;
        background-position: center;
        overflow: hidden;
        position: relative;
        @media (max-width: 991px) {
          width: 55px !important;
          &:nth-child(2n-1) {
            display: none;
          }
        }
        .img-box {
          background-position: center;
          background-size: cover;
          height: 100%;
        }
      }
    }
    .ant-slider {
      margin: 0;
      padding: 0;
      height: 100%;
    }
    .ant-slider-rail,
    .ant-slider-track,
    .ant-slider-step {
      height: 100%;
      background-color: transparent;
    }
    .frame {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      padding: 0.5rem 0;
      .ant-slider-handle {
        height: 80px;
        border-radius: 0;
        width: 1px;
        border: none;
        top: -30px;
        background-color: #ff5c00;
        margin: 0;
        &::before {
          content: "";
          width: 7px;
          height: 12px;
          background-color: #ff5c00;
          position: absolute;
          left: -3px;
          top: 0;
        }
        &::after {
          content: "";
          width: 7px;
          height: 100%;
          background-color: transparent;
          position: absolute;
          left: -3px;
          top: 0;
        }
      }
    }
    .frame-merge {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      padding: 0.5rem 0;
      display: flex;
      justify-content: space-between;
      .ant-slider-rail {
        height: 2px;
        background-color: #f1edff;
      }
      .ant-slider-track {
        height: 2px;
      }
      .active .ant-slider-track {
        background-color: $primary-color;
      }
      .ant-slider-step {
        position: relative;
      }
      .ant-slider-mark-text,
      .ant-slider-mark-text-active {
        color: $white;
      }
      .ant-slider-handle {
        width: 12px;
        height: 12px;
        border-radius: 12px;
        border: none;
        top: -4px;
        background-color: #ffbb00;
        margin: 0;
        display: none;
      }
      .active .ant-slider-handle {
        display: block;
      }
    }
    .range {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      .ant-slider-handle {
        height: 48px;
        width: 10px;
        border-left: solid 4px #f7b500;
        border-right: solid 4px #f7b500;
        border-top: solid 16px #f7b500;
        border-bottom: solid 16px #f7b500;
        top: -4px;
        background-color: $light-white;
        margin: 0;
        cursor: ew-resize;
        &.ant-slider-handle-1 {
          border-radius: 4px 0 0 4px;
        }
        &.ant-slider-handle-2 {
          border-radius: 0 4px 4px 0;
        }
      }
      .include {
        position: absolute;
        top: -4px;
        height: 48px;
        border-top: solid 2px #f7b500;
        border-bottom: solid 2px #f7b500;
      }
      .cover {
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        height: 100%;
        @include border_raduis("raduis-normal");
      }
      .miniLengthLimit {
        position: absolute;
        color: #ff5c00;
        left: 0;
        bottom: -40px;
        width: 100%;
        text-align: center;
      }
    }
  }
  .close {
    @include font_color("font-secondary");
    position: absolute;
    background-color: transparent;
    border-radius: 100%;
    right: -0.5rem;
    top: -1.5rem;
    border: none;
    padding: 0;
    line-height: 0;
    cursor: pointer;
    z-index: 9;
    &:focus {
      outline: none;
    }
    &:hover {
      @include font_color("preview-close-hover-color");
    }
    @media (min-width: 992px) {
      right: -1.5rem;
    }
  }
  .CropRotatePreview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .videoWrap {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ReactCrop {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 9;
      @include border_raduis("preview-video-cont-raduis");
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      & > div:first-child {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        opacity: 0;
      }
      .ord-e::after {
        background-color: #f7b500;
        border: none;
        width: 8px;
        height: 8px;
        right: 1px;
      }
      .ord-n::after {
        background-color: #f7b500;
        border: none;
        width: 8px;
        height: 8px;
        top: 1px;
      }
      .ord-s::after {
        background-color: #f7b500;
        border: none;
        width: 8px;
        height: 8px;
        bottom: 1px;
      }
      .ord-w::after {
        background-color: #f7b500;
        border: none;
        width: 8px;
        height: 8px;
        left: 1px;
      }
      .ord-sw {
        cursor: nesw-resize;
      }
      .ord-sw::before {
        position: absolute;
        content: "";
        display: block;
        background-color: #f7b500;
        border: none;
        width: 4px;
        height: 30px;
        bottom: 3px;
        left: 2px;
      }
      .ord-sw::after {
        position: absolute;
        content: "";
        display: block;
        background-color: #f7b500;
        border: none;
        width: 30px;
        height: 4px;
        bottom: 3px;
        left: 2px;
      }
      .ord-nw {
        cursor: nwse-resize;
      }
      .ord-nw::before {
        position: absolute;
        content: "";
        display: block;
        background-color: #f7b500;
        border: none;
        width: 4px;
        height: 30px;
        top: 3px;
        left: 2px;
      }
      .ord-nw::after {
        position: absolute;
        content: "";
        display: block;
        background-color: #f7b500;
        border: none;
        width: 30px;
        height: 4px;
        top: 3px;
        left: 2px;
      }
      .ord-se {
        cursor: nwse-resize;
      }
      .ord-se::before {
        position: absolute;
        content: "";
        display: block;
        background-color: #f7b500;
        border: none;
        width: 4px;
        height: 30px;
        bottom: 3px;
        right: 2px;
      }
      .ord-se::after {
        position: absolute;
        content: "";
        display: block;
        background-color: #f7b500;
        border: none;
        width: 30px;
        height: 4px;
        bottom: 3px;
        right: 2px;
      }
      .ord-ne {
        cursor: nesw-resize;
      }
      .ord-ne::before {
        position: absolute;
        content: "";
        display: block;
        background-color: #f7b500;
        border: none;
        width: 4px;
        height: 30px;
        top: 3px;
        right: 2px;
      }
      .ord-ne::after {
        position: absolute;
        content: "";
        display: block;
        background-color: #f7b500;
        border: none;
        width: 30px;
        height: 4px;
        top: 3px;
        right: 2px;
      }
      .ReactCrop__drag-elements {
        &::before {
          content: "";
          display: block;
          width: 48px;
          height: 2px;
          background-color: #f7b500;
          position: absolute;
          left: 50%;
          top: 50%;
          margin-left: -25px;
        }
        &::after {
          content: "";
          display: block;
          height: 48px;
          width: 2px;
          background-color: #f7b500;
          position: absolute;
          left: 50%;
          top: 50%;
          margin-top: -25px;
        }
      }
    }
  }
  .RemoveWatermarkPreview {
    .MultiCrops {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 9;
      cursor: cell;
      & > div {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        & > img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
        }
        & > div {
          background: rgba(131, 114, 255, 0.2) !important;
          border: 1px solid #8372ff;
          box-shadow: none !important;
          opacity: 1 !important;
          .rmc-icon-container {
            background: $primary-color !important;
          }
          .rmc-icon-container {
            display: none;
          }
          &.active {
            background: rgba(131, 114, 255, 0.4) !important;
            .rmc-icon-container {
              display: block;
            }
          }
        }
      }
    }
  }
  .SubtitlePreview {
    .Draggable {
      position: absolute;
      z-index: 9;
      left: 0;
      top: 0;
      right: 0;
      margin: 0 auto;
      overflow: hidden;
      .react-draggable {
        width: fit-content;
        max-width: 80%;
        position: absolute;
        cursor: cell;
        padding: 0.2rem;
        word-wrap: break-word;
        text-align: left;
      }
    }
  }
  .VideoPreview {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    flex-direction: column;
    .operation {
      position: absolute;
      top: 16px;
      right: 16px;
      background-color: $deep-dark;
      display: flex;
      align-items: center;
      color: $white;
      padding: 12px 0;
      border-radius: 4px;
      cursor: pointer;
      z-index: 2;
      .operation-item {
        padding: 0 1rem;
        display: flex;
        align-items: center;
        &:first-child {
          border-right: 1px solid $border-gray;
        }
        svg {
          margin-right: 0.5rem;
        }
      }
    }
    .ant-dropdown {
      background-color: $deep-dark;
      color: $white;
      padding: 12px 1rem;
      top: 50px !important;
      border-radius: 4px;
      .downloadMenu {
        & > div {
          text-align: left;
          font-size: 14px;
          line-height: 18px;
          &:first-child {
            margin-bottom: 1rem;
          }
          &:hover {
            color: $primary-color;
          }
        }
      }
    }
    .videoPreview-files {
      width: 100%;
      max-height: 80%;
      overflow-y: auto;
      margin-top: 24px;
      margin-left: 0;
      flex-shrink: 0;
      padding-right: 8px;
      &::-webkit-scrollbar {
        width: 4px;
        @include background_color("scrollbar-track-bg");
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        @include background_color("scrollbar-thumb-bg");
      }

      &::-webkit-scrollbar-track {
        border-radius: 4px;
        @include background_color("scrollbar-track-bg");
      }
      & > div {
        display: flex;
        align-items: flex-start;
        overflow-x: scroll;
        width: 100%;
      }
      & > div > div {
        width: 136px;
        height: 86px;
        flex-shrink: 0;
        padding: 8px;
        border: 1px solid transparent;
        @include border_raduis("raduis-secondary");
        cursor: pointer;
        &.active {
          border-color: $primary-color;
        }
        &:not(:last-child) {
          margin-bottom: 24px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          @include border_raduis("raduis-normal");
        }
      }
    }
    @media (min-width: 992px) {
      flex-direction: row;
      .videoPreview-files {
        width: auto;
        margin-top: 0;
        margin-left: 40px;
        & > div {
          display: block;
          overflow-x: hidden;
        }
        & > div > div {
          width: 256px;
          height: 151px;
        }
      }
    }
  }

  .bg1 {
    background: url(./images/bg1.png) no-repeat center center/cover;
  }
  .bg2 {
    background: url(./images/bg2.png) no-repeat center center/cover;
  }
  .bg3 {
    background: url(./images/bg3.png) no-repeat center center/cover;
  }
  .bg4 {
    background: url(./images/bg4.png) no-repeat center center/cover;
  }
  .bg5 {
    background: url(./images/bg5.png) no-repeat center center/cover;
  }
  .bg6 {
    background: url(./images/bg6.png) no-repeat center center/cover;
  }
  .bg7 {
    background: url(./images/bg7.png) no-repeat center center/cover;
  }
  .bg8 {
    background: url(./images/bg8.png) no-repeat center center/cover;
  }
  .bg9 {
    background: url(./images/bg9.png) no-repeat center center/cover;
  }
  .bg10 {
    background: url(./images/bg10.png) no-repeat center center/cover;
  }
  .bg11 {
    background: url(./images/bg11.png) no-repeat center center/cover;
  }
  .bg12 {
    background: url(./images/bg12.png) no-repeat center center/cover;
  }
  .bg13 {
    background: url(./images/bg13.png) no-repeat center center/cover;
  }
  .bg14 {
    background: url(./images/bg14.png) no-repeat center center/cover;
  }
  .bg15 {
    background: url(./images/bg15.png) no-repeat center center/cover;
  }
}
