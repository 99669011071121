//通用颜色
$primary-color: #8372ff !default;
$normal-color: #979798 !default;
$white-color-1: #ffffff !default;
$white-color-2: #f7f7fa !default;
$gray-color-2: #5e5f62 !default;
$black: #0f0f10 !default;
$deep-black: #1b1b1b !default;
$dark: #2e2f30 !default;
$deep-dark: #000000 !default;

$deep-gray: #353536 !default;
$gray: #3b3c3d !default;
$low-gray: #5e5f62 !default;

$white: #d8d8d8 !default;
$lightless-white: #e3e3e3 !default;
$light-white: #ffffff !default;
$darkless-white: #a1a1a2 !default;
$darkest-white: #898a8b !default;

$border-gray: #434445 !default;
$border-light: #a1a1a2 !default;

@import "./sass/variables/variables";
@import "./sass/minxin.scss";

.Navbar {
  @include background_color("bg-secondary");
  @include border("navbar-right-border-color", 1px, solid, "border-right");
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 14 0 24px;
  border-right: 1px solid $deep-gray;
  box-sizing: border-box;
  flex-shrink: 0;
  box-shadow: 1px 0px 0px rgba(255, 255, 255, 0.16);
  order: 1;
  .nav-bottom {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-y: auto;
    padding: 6px 10px;
    .home-link {
      display: none;
    }
    .page-btn {
      @include background_color("bg-hight");
      @include font_color("icon-color");
      @include box-shadow("icon-next-shadow");
      @include border("icon-border");
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 28px;
      flex-shrink: 0;
      border-radius: 8px;
      svg {
        transform: matrix(1, 0, 0, -1, 0, 0);
      }
    }
    .pre-icon {
      margin-bottom: 28px;
      cursor: pointer;
    }
    .next-icon {
      margin-bottom: 28px;
      cursor: pointer;
    }
    .nav-item {
      @include font_color("icon-color");
      @include border_raduis("raduis-secondary");

      cursor: pointer;
      margin: 0 3px;
      svg {
        display: block;
      }
    }
  }
  .nav-work {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    padding: 6px 16px;
    svg {
      display: block;
      flex-shrink: 0;
    }
    .nav-reback {
      @include background_color("bg-hight");
      @include font_color("navbar-reback-color");
      @include border("icon-reback-border");
      cursor: pointer;
      margin-right: 8px;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      &.disabled {
        cursor: not-allowed;
        color: $normal-color;
      }
    }
    .nav-item {
      display: block;
      border-radius: 8px;
      cursor: pointer;
      color: $primary-color;
    }
  }
  .download {
    display: none;
    svg {
      display: block;
    }
  }
  @media (min-width: 992px) {
    order: 0;
    width: 92px;
    height: 100%;
    flex-direction: column;
    padding: 26px 0 24px;
    .nav-bottom {
      flex-direction: column;
      padding: 0 0;
      overflow: visible;
      .home-link {
        @include font_color("pc-logo-color");
        display: inline;
      }
      .nav-item {
        @include background_color("bg-hight");
        @include box_shadow("icon-shadow");
        margin: 0 0 28px 0;
        @include border("icon-border");
      }
    }
    .nav-work {
      flex-direction: column;
      padding: 0;
      .nav-reback {
        margin-bottom: 34px;
        margin-right: 0;
      }
      .nav-item {
        @include background_color("bg-hight");
        @include box_shadow("icon-shadow");
      }
    }
    .download {
      display: block;
    }
  }
}
