//通用颜色
$primary-color: #8372ff !default;
$normal-color: #979798 !default;
$white-color-1: #ffffff !default;
$white-color-2: #f7f7fa !default;
$gray-color-2: #5e5f62 !default;
$black: #0f0f10 !default;
$deep-black: #1b1b1b !default;
$dark: #2e2f30 !default;
$deep-dark: #000000 !default;

$deep-gray: #353536 !default;
$gray: #3b3c3d !default;
$low-gray: #5e5f62 !default;

$white: #d8d8d8 !default;
$lightless-white: #e3e3e3 !default;
$light-white: #ffffff !default;
$darkless-white: #a1a1a2 !default;
$darkest-white: #898a8b !default;

$border-gray: #434445 !default;
$border-light: #a1a1a2 !default;

@import "./sass/variables/variables";
@import "./sass/minxin.scss";

.GifWork {
  color: $white;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 1.25rem;
  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
    button {
      background-color: $gray;
      border-color: $gray;
      color: $white;
    }
    .ant-slider-track,
    .ant-slider-rail {
      background-color: $normal-color;
    }
  }
  input {
    background-color: $gray;
    margin-top: 0.5rem;
    border: 1px solid #454649;
    border-radius: 0;
    color: $white;
    padding: 2px 0.5rem;
    font-size: 14px;
    width: 100%;
    &:focus,
    &:hover {
      background-color: $gray;
    }
  }
  .ant-select {
    color: $white;
    font-size: 14px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: $gray;
    margin-top: 0.5rem;
    border: 1px solid #454649;
  }
  .ant-select-arrow {
    color: $white;
    top: 60%;
  }
  .ant-picker {
    padding: 0;
    border-radius: 0;
    border: none;
    background-color: transparent;
    width: 100%;
  }
  .ant-picker-focused {
    box-shadow: none;
  }
  .ant-slider {
    margin-top: 3rem;
  }
  .ant-slider-track {
    background-color: $primary-color;
  }
  .ant-slider-mark-text {
    color: $white;
  }
  .ant-slider-handle {
    border-radius: 0;
    background-color: $normal-color;
    width: 10px;
    height: 10px;
    box-shadow: none;
    border-color: $normal-color;
    &::after {
      content: "";
      width: 0;
      height: 0;
      border-top: solid 5px $normal-color;
      border-left: solid 5px transparent;
      border-right: solid 5px transparent;
      position: absolute;
      left: -2px;
      top: 8px;
    }
  }
  .ant-slider-handle.ant-tooltip-open {
    border-color: #ffbb00;
    background-color: #ffbb00;
    &::after {
      border-top: solid 5px #ffbb00;
    }
  }
  .ant-btn[disabled] {
    background-color: $gray;
    color: $normal-color;
    border: none;
  }
  button:not(:first-child) {
    margin-top: 0.5rem;
    font-size: 18px;
    line-height: 1;
  }
}
