//通用颜色
$primary-color: #8372ff !default;
$normal-color: #979798 !default;
$white-color-1: #ffffff !default;
$white-color-2: #f7f7fa !default;
$gray-color-2: #5e5f62 !default;
$black: #0f0f10 !default;
$deep-black: #1b1b1b !default;
$dark: #2e2f30 !default;
$deep-dark: #000000 !default;

$deep-gray: #353536 !default;
$gray: #3b3c3d !default;
$low-gray: #5e5f62 !default;

$white: #d8d8d8 !default;
$lightless-white: #e3e3e3 !default;
$light-white: #ffffff !default;
$darkless-white: #a1a1a2 !default;
$darkest-white: #898a8b !default;

$border-gray: #434445 !default;
$border-light: #a1a1a2 !default;

@import "./sass/variables/variables";
@import "./sass/minxin.scss";

.ConvertWork {
  color: $white;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 1.25rem;
  .title {
    text-align: center;
    display: block;
    margin-bottom: 1rem;
    font-size: 14px;
  }
  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
    button {
      background-color: $gray;
      border-color: $gray;
      color: $white;
    }
    .cenvertBtn {
      color: $normal-color !important;
    }
  }
  .ant-btn[disabled] {
    background-color: $gray;
    color: $normal-color;
    border: none;
  }
  button:not(:first-child) {
    margin-top: 0.5rem;
  }
  .cenvertBtn {
    height: 32px;
    background: #313233;
    border: 1px solid #454649;
    cursor: pointer;
    text-align: center;
    line-height: 32px;
    margin: 0 5px;
    &:hover,
    &.active {
      color: $primary-color;
    }
  }
  .ant-row {
    hr {
      border-color: #000;
      margin: 20px 5px 25px 5px;
    }
  }
  .save button {
    font-size: 18px;
    line-height: 1;
  }
}
