//通用颜色
$primary-color: #8372ff !default;
$normal-color: #979798 !default;
$white-color-1: #ffffff !default;
$white-color-2: #f7f7fa !default;
$gray-color-2: #5e5f62 !default;
$black: #0f0f10 !default;
$deep-black: #1b1b1b !default;
$dark: #2e2f30 !default;
$deep-dark: #000000 !default;

$deep-gray: #353536 !default;
$gray: #3b3c3d !default;
$low-gray: #5e5f62 !default;

$white: #d8d8d8 !default;
$lightless-white: #e3e3e3 !default;
$light-white: #ffffff !default;
$darkless-white: #a1a1a2 !default;
$darkest-white: #898a8b !default;

$border-gray: #434445 !default;
$border-light: #a1a1a2 !default;

@import "./sass/variables/variables";
@import "./sass/minxin.scss";

.PreviewContent {
  @include background_color("preview-bg");
  height: 100%;
  .PreviewContent-container {
    @include background_color("bg-main");
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
  }
  .PreviewContent-content {
    max-width: 1280px;
    height: 100%;
    width: 100%;
    padding: 0 20px;
    @media (min-width: 992px) {
      padding: 0 40px;
    }
  }
  .UploadToServer {
    width: 60%;
    height: 100%;
  }
  // .files-btn-mobile {
  //   position: absolute;
  //   left: 10px;
  //   top: 5px;
  //   color: $white;
  //   @media (min-width: 992px) {
  //     display: none;
  //   }
  // }

  .fileList {
    @include font_color("font-normal");
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100% !important;
    border: none !important;
    padding: 0 !important;
    margin-top: 50px;
    // position: absolute;
    // color: $white;
    top: 50%;
    left: 50%;
    height: auto;
    .fileList-title {
      display: none;
      align-items: center;
      justify-content: center;
    }
    .fileList-title-mobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 327px;
      margin-bottom: 20px;
    }
    .fileItem {
      @include border_raduis("raduis-normal");
      overflow: hidden;
      width: 96px;
      height: 57px;
      position: relative;
      background: url("./images/timg.svg") no-repeat center center;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &:hover {
        .fileItem-modal {
          display: flex;
        }
      }
      .fileItem-modal {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        display: none;
        align-items: flex-end;
        color: $white-color-1;
        .more-btn {
          position: absolute;
          top: 0;
          right: 4px;
        }
        .fileItem-info {
          padding: 0 4px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 12px;
          line-height: 15px;
        }
        .fileItem-status {
          position: absolute;
          top: 2px;
          left: 2px;
          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
    .more-files-btn-cont {
      display: none;
    }
    .more-files-btn {
      @include border("border-normal");
      @include border_raduis("preview-filelist-btn-raduis");
      width: 20px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .ant-divider-horizontal.ant-divider-with-text {
      @include font_color("font-normal");
      &::before,
      &::after {
        border-top-color: $border-gray;
        width: 28px;
        top: 0;
      }
    }
    @media (min-width: 992px) {
      margin-top: 100px;
      width: auto !important;
      .fileItem {
        width: 130px;
        height: 74px;
      }
      .more-files-btn-cont {
        display: block;
      }
      .fileList-title {
        display: flex;
      }
      .fileList-title-mobile {
        display: none;
      }
    }
  }
  @media (min-width: 992px) {
    .PreviewContent-container {
      @include border_raduis("preview-raduis");
    }
  }
}

.file-operation {
  width: 96px;
  height: 36px;
  padding: 6px 14px;
  background: $white-color-2;
  color: $white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    cursor: pointer;
    color: $gray-color-2;
    &:hover {
      color: $primary-color;
    }
  }
}
