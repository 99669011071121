//通用颜色
$primary-color: #8372ff !default;
$normal-color: #979798 !default;
$white-color-1: #ffffff !default;
$white-color-2: #f7f7fa !default;
$gray-color-2: #5e5f62 !default;
$black: #0f0f10 !default;
$deep-black: #1b1b1b !default;
$dark: #2e2f30 !default;
$deep-dark: #000000 !default;

$deep-gray: #353536 !default;
$gray: #3b3c3d !default;
$low-gray: #5e5f62 !default;

$white: #d8d8d8 !default;
$lightless-white: #e3e3e3 !default;
$light-white: #ffffff !default;
$darkless-white: #a1a1a2 !default;
$darkest-white: #898a8b !default;

$border-gray: #434445 !default;
$border-light: #a1a1a2 !default;

@import "./sass/variables/variables";
@import "./sass/minxin.scss";

.ReverseWork {
  @include font_color("font-normal");
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 1.25rem 0;
  overflow-x: hidden;
  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
    button {
      @include background_color("work-save-disabled-bg");
      @include border("work-save-disabled-border-color");
      @include font_color("work-save-disabled-color");
    }
    .ant-slider-track,
    .ant-slider-rail {
      background-color: $normal-color;
    }
  }
  .ant-slider {
    margin-top: 2rem;
  }

  .ant-btn[disabled] {
    background-color: $gray;
    color: $normal-color;
    border: none;
  }
  button:not(:first-child) {
    margin-top: 0.5rem;
    font-size: 16px;
    font-weight: 700;
  }
  hr {
    margin: 18px auto 24px;
    border: none;
    @include border("work-hr-color", 1px, solid, border-bottom);
    @include width("work-hr-width");
  }
  .setSpeedBtn {
    @include background_color("work-btn-bg");
    @include font_color("font-normal");
    @include border("work-btn-border");
    @include border_raduis("work-btn-raduis");
    height: 32px;
    cursor: pointer;
    text-align: center;
    line-height: 32px;
    margin: 0 auto;
    &:hover,
    &.active {
      border: 1px solid $primary-color;
      box-shadow: 0px 0px 6px rgba(131, 114, 255, 0.4);
    }
  }
  .setVolumeBtn {
    @include background_color("work-btn-bg");
    @include font_color("font-normal");
    @include border("work-btn-border");
    @include border_raduis("work-btn-raduis");
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 4px 0;
    &:hover,
    &.active {
      border: 1px solid $primary-color;
      box-shadow: 0px 0px 6px rgba(131, 114, 255, 0.4);
      color: $primary-color;
    }
  }
  button.save {
    @include border_raduis("work-save-raduis");
    font-size: 16px;
    font-weight: bold;
  }
}
