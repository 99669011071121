//通用颜色
$primary-color: #8372ff !default;
$normal-color: #979798 !default;
$white-color-1: #ffffff !default;
$white-color-2: #f7f7fa !default;
$gray-color-2: #5e5f62 !default;
$black: #0f0f10 !default;
$deep-black: #1b1b1b !default;
$dark: #2e2f30 !default;
$deep-dark: #000000 !default;

$deep-gray: #353536 !default;
$gray: #3b3c3d !default;
$low-gray: #5e5f62 !default;

$white: #d8d8d8 !default;
$lightless-white: #e3e3e3 !default;
$light-white: #ffffff !default;
$darkless-white: #a1a1a2 !default;
$darkest-white: #898a8b !default;

$border-gray: #434445 !default;
$border-light: #a1a1a2 !default;

@import "./sass/variables/variables";
@import "./sass/minxin.scss";

.ScreenshotWork {
  color: $white;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 1.25rem 0;
  .wrap {
    display: flex;
    flex-direction: column;
    flex: auto;
    overflow: hidden;
    .itemlist {
      flex: auto;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 4px;
        @include background_color("scrollbar-track-bg");
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        @include background_color("scrollbar-thumb-bg");
      }
      &::-webkit-scrollbar-track {
        border-radius: 4px;
        @include background_color("scrollbar-track-bg");
      }
    }
  }
  .add {
    color: $white;
    font-size: 14px;
    padding: 0 1rem;
    text-align: center;
    margin-bottom: 1rem;
    button {
      width: 100%;
      text-align: left;
      padding: 4px 1.5rem;
      border: none;
      background-color: $gray;
      cursor: not-allowed;
      &[disabled] {
        opacity: 0.5;
      }
      &:not([disabled]) {
        cursor: pointer;
      }
      &:not([disabled]):hover {
        color: $primary-color;
      }
    }
    button:focus {
      outline: none;
    }
    .anticon {
      padding: 4px;
      margin-right: 0.5rem;
    }
  }
  .itemList {
    padding: 0 1rem;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
      @include background_color("scrollbar-track-bg");
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      @include background_color("scrollbar-thumb-bg");
    }
    &::-webkit-scrollbar-track {
      border-radius: 4px;
      @include background_color("scrollbar-track-bg");
    }
  }
  .item {
    position: relative;
    border: solid 1px transparent;
    background: url(./images/screenshotBg.png) no-repeat center center/cover;
    height: 72px;
    overflow: hidden;
    &.active {
      background: #3b3c3d;
    }
    & > div {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    // &.active,
    // &:hover {
    //   background-color: $black;
    // }
    &:hover {
      .close {
        display: block;
      }
    }
    .close {
      cursor: pointer;
      display: none;
      position: absolute;
      background-color: $gray;
      border-radius: 100%;
      right: 0.2rem;
      top: 0.2rem;
      border: none;
      padding: 0;
      line-height: 0;
      cursor: pointer;
      &:focus {
        outline: none;
      }
      &:hover {
        background-color: $white;
        color: $dark;
      }
    }
  }
  .save {
    padding: 1rem 1rem 0;
    button {
      font-size: 18px;
      line-height: 1;
    }
    button:not(:first-child) {
      margin-top: 1rem;
    }
    button[disabled] {
      opacity: 0.5;
      background-color: $gray;
      border-color: $gray;
      color: $white;
    }
  }
  .img-box {
    background-position: center;
    background-size: cover;
    height: 100%;
  }
  .ant-row .ant-row {
    margin: 0 !important;
    padding-left: 0.2rem;
  }
}
