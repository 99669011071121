//通用颜色
$primary-color: #8372ff !default;
$normal-color: #979798 !default;
$white-color-1: #ffffff !default;
$white-color-2: #f7f7fa !default;
$gray-color-2: #5e5f62 !default;
$black: #0f0f10 !default;
$deep-black: #1b1b1b !default;
$dark: #2e2f30 !default;
$deep-dark: #000000 !default;

$deep-gray: #353536 !default;
$gray: #3b3c3d !default;
$low-gray: #5e5f62 !default;

$white: #d8d8d8 !default;
$lightless-white: #e3e3e3 !default;
$light-white: #ffffff !default;
$darkless-white: #a1a1a2 !default;
$darkest-white: #898a8b !default;

$border-gray: #434445 !default;
$border-light: #a1a1a2 !default;

@import "./sass/variables/variables";
@import "./sass/minxin.scss";

.RemoveWatermarkWork {
  @include font_color("font-normal");
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 1.5rem 0;
  .wrap {
    display: flex;
    flex-direction: column;
    flex: auto;
    overflow: hidden;
    .itemlist {
      padding: 0 1.5rem;
      flex: auto;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 4px;
        @include background_color("scrollbar-track-bg");
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        @include background_color("scrollbar-thumb-bg");
      }
      &::-webkit-scrollbar-track {
        border-radius: 4px;
        @include background_color("scrollbar-track-bg");
      }
    }
  }
  .add {
    font-size: 14px;
    padding: 0 1rem;
    text-align: center;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-right: 10px;
      @include font_color("work-spc-btn-color");
    }
    span {
      @include font_color("font-normal");
    }
  }
  .item {
    @include filter("work-item-brightness-dark");
    @include background_color("work-btn-bg");
    @include font_color("font-normal");
    @include border_raduis("work-btn-raduis");
    padding: 1rem;
    cursor: pointer;
    position: relative;
    border: solid 1px transparent;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    &:hover,
    &.active {
      filter: brightness(1);
      border: solid 1px $primary-color;
      box-shadow: 0px 0px 6px rgba(131, 114, 255, 0.4);
    }
    &:hover {
      .close {
        display: block;
      }
    }
    .item-time-cont {
      display: flex;
      align-items: center;
      @include font_color("font-secondary");
      .item-time {
        display: flex;
        align-items: center;
        @include background_color("work-input-bg");
        height: 24px;
        border-radius: 4px;
      }
    }
    input {
      padding: 0;
      margin: 0;
      font-size: 12px;
      padding: 2px 0.5rem;
      @include background_color("work-input-bg");
      @include font_color("font-normal");
      text-align: center;
    }
    .close {
      display: none;
      position: absolute;
      right: 0rem;
      top: 0rem;
      border: none;
      padding: 0;
      line-height: 0;
      cursor: pointer;
      @include font_color("font-normal");
      background-color: transparent;
      &:focus {
        outline: none;
      }
      &:hover {
        color: $primary-color;
      }
    }
  }
  .save {
    padding: 1.5rem 1.5rem 0;
    button {
      line-height: 1;
      font-weight: bold;
      @include border_raduis("work-save-raduis");
    }
    button:not(:first-child) {
      margin-top: 1rem;
    }
    button[disabled] {
      opacity: 0.5;
      @include background_color("work-save-disabled-bg");
      @include border("work-save-disabled-border-color");
      @include font_color("work-save-disabled-color");
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
  .ant-row .ant-row {
    margin: 0 !important;
    padding-left: 0.2rem;
  }
}
