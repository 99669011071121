$themes: (
  //亮色主题
  light:
    (
      //背景色
      bg-main: #f8f8fa,
      bg-secondary: #ecebf0,
      bg-hight: #f8f8fa,
      //边框色
      border-hight: #f7f7fa,
      border-normal: #b5b5b7,
      border-gray: rgba(27, 27, 27, 0.15),
      //字体颜色
      font-normal: #535353,
      font-secondary: rgba(27, 27, 27, 0.5),
      //圆角值
      raduis-normal: 8px,
      raduis-secondary: 12px,
      // tooltip 背景色
      tooltip-bg: #f8f8fa,
      //modal 弹窗圆角
      modal-border-raduis: 12px,
      //modal 弹窗背景色
      modal-bg: #ffffff,
      //modal 蒙层的背景色
      modal-mask-bg: rgba(0, 0, 0, 0.1),
      //滚动条背景色
      scrollbar-track-bg: #ecebf0,
      //滚动条thumb背景色
      scrollbar-thumb-bg: #ffffff,
      //侧边栏icon阴影
      icon-shadow: (0px -4px 12px rgba(255, 255, 255, 0.4), 0px 4px 12px rgba(131, 114, 255, 0.1)),
      //侧边栏icon颜色
      icon-color: #7c7c80,
      //侧边栏icon边框颜色
      icon-border: rgba(27, 27, 27, 0.08),
      //侧边栏 reback icon边框颜色
      icon-reback-border: rgba(27, 27, 27, 0.08),
      //侧边栏 next icon阴影
      icon-next-shadow: (0px -4px 12px rgba(255, 255, 255, 0.4), 0px 4px 12px rgba(131, 114, 255, 0.1)),
      //pc端logo颜色
      pc-logo-color: $primary-color,
      //mobile端logo颜色
      mobile-logo-color: #1b1b1b,
      //侧边栏返回按钮颜色
      navbar-reback-color: #1b1b1bbf,
      //侧边栏右侧边框色
      navbar-right-border-color: #ecebf0,
      //工作区左侧边框色
      work-left-border-color: #ecebf0,
      //文件库背景色
      filelist-bg: #ffffff,
      //preview区外围背景色
      preview-bg: #ecebf0,
      //preview区中间圆角值
      preview-raduis: 24px,
      //preview区关闭按钮hover 颜色
      preview-close-hover-color: $primary-color,
      //preview filelist 展开按钮raduis
      preview-filelist-btn-raduis: 4px,
      //preview video and control 边框颜色
      preview-video-border: #f2f2f7,
      // preview merge track 隐藏色
      perview-merge-track-bg: #dddddd,
      //url解析跳转按钮icon默认色
      upload-url-icon-color: #89898b,
      //url解析跳转按钮icon hover色
      upload-url-icon-hover-color: $primary-color,
      //process界面 产品卡片背景色
      process-product-card-bg: #ffffff,
      //process界面 产品卡片圆角
      process-product-card-raduis: 12px,
      //process 进度条底色
      process-progress-bg: rgba(27, 27, 27, 0.15),
      //process 大的进度条的圆角
      process-normal-cont-raduis: 20px,
      //process 大的进度条的底色
      process-normal-cont-bg: rgb(214, 214, 217),
      //process 大的进度条的字体颜色
      process-normal-cont-color: #1b1b1b,
      //work 界面的按钮边框颜色
      work-btn-border: rgba(27, 27, 27, 0.08),
      //work 界面的按钮背景颜色
      work-btn-bg: #f8f8fa,
      //work 界面的按钮圆角
      work-btn-raduis: 8px,
      //work 界面 hr 颜色
      work-hr-color: rgba(27, 27, 27, 0.08),
      //work 界面hr的宽度
      work-hr-width: calc(100% - 40px),
      //work input 背景颜色
      work-input-bg: #eaeaf2,
      //work 界面 save 按钮 raduis
      work-save-raduis: 8px,
      //work 界面 save 按钮 禁用状态下的背景色
      work-save-disabled-bg: #a2a2a2,
      //work 界面 save 按钮 禁用状态下的边框色
      work-save-disabled-border-color: #a2a2a2,
      //work 界面 save 按钮 禁用状态下的字体颜色
      work-save-disabled-color: #ffffff,
      //work 界面icon 特定颜色
      work-spc-btn-color: $primary-color,
      //work 界面 item 没有选中的item的滤镜
      work-item-brightness-dark: brightness(0.98),
      // work 界面 slider 默认背景色
      work-slider-bg: rgba(27, 27, 27, 0.15),
      //work 界面 resize 背景图选中raduis
      work-resize-bgPic-active-raduis: 12px,
      //work 界面 subtitle 快速定位默认背景色
      work-subtitle-position-bg: rgb(219, 218, 223),
      //work 界面 addFileBtn 按钮边框颜色
      work-addFileBtn-border: rgba(27, 27, 27, 0.08),
      //work 界面 addFile框框 边框颜色
      work-upload-border: rgba(27, 27, 27, 0.15),
      //preview 界面 track 背景色
      preview-track-bg: #ffffff,
      //preview 界面 video 播放器 raduis
      preview-video-cont-raduis: 12px,
      //purchase 弹窗 主字体色
      purchase-color: #1b1b1b,
      //purchase 弹窗 list 标签内容
      purchase-color-secondary: rgba(27, 27, 27, 0.75),
      //purchase 弹窗 input
      purchase-input-bg: #ffffff,
      //purchase 弹窗 input
      purchase-input-border: rgba(27, 27, 27, 0.15),
      //purchase 弹窗 list 标签内容
      purchase-list-before-content:
        url('data:image/svg+xml,<svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M13.1173 0.617205C13.4479 0.947795 13.4615 1.47945 13.1482 1.82647L5.5971 10.1898C5.38645 10.4231 5.02214 10.4288 4.80425 10.2023L0.618497 5.85003C0.275035 5.49291 0.280574 4.92659 0.630955 4.57625C0.958969 4.24828 1.48123 4.21965 1.84313 4.50981L4.91358 6.97163C5.08104 7.10589 5.32254 7.09336 5.47521 6.94249L11.8793 0.613552C12.2227 0.274113 12.7759 0.275744 13.1173 0.617205Z" fill="%231b1b1b"/></svg>'),
      //select 下拉icon颜色
      select-arrow-icon-color: rgba(27, 27, 27, 0.5)
    ),
  //暗色主题
  dark:
    (
      //背景色
      bg-main: #0f0f10,
      bg-secondary: #1b1b1b,
      bg-hight: #2e2f30,
      //边框色
      border-hight: #a1a1a2,
      border-normal: #5e5f62,
      border-gray: #434445,
      //字体颜色
      font-normal: rgba(255, 255, 255, 0.8),
      font-secondary: #979798,
      //圆角值
      raduis-normal: 0px,
      raduis-secondary: 8px,
      // tooltip 背景色
      tooltip-bg: #2e2f30,
      //modal 弹窗圆角
      modal-border-raduis: 4px,
      //modal 弹窗背景色
      modal-bg: #2e2f30,
      //modal 蒙层的背景色
      modal-mask-bg: rgba(0, 0, 0, 0.45),
      //滚动条背景色
      scrollbar-track-bg: #2e2f30,
      //滚动条thumb背景色
      scrollbar-thumb-bg: #5e5f62,
      //侧边栏icon阴影
      icon-shadow: (0px -3px 8px rgba(205, 198, 255, 0.06), 0px 5px 12px rgba(15, 15, 16, 0.7)),
      //侧边栏icon颜色
      icon-color: rgba(255, 255, 255, 0.8),
      //侧边栏icon边框颜色
      icon-border: rgba(46, 47, 48, 1),
      //侧边栏 reback icon边框颜色
      icon-reback-border: #434445,
      //侧边栏 next icon阴影
      icon-next-shadow: (0px 5px 12px rgba(15, 15, 16, 0.7), 0px -3px 8px rgba(205, 198, 255, 0.06)),
      //pc端logo颜色
      pc-logo-color: #ffffff,
      //mobile端logo颜色
      mobile-logo-color: #e3e3e3,
      //侧边栏返回按钮颜色
      navbar-reback-color: #ffffffcc,
      //侧边栏右侧边框色
      navbar-right-border-color: #353536,
      //工作区左侧边框色
      work-left-border-color: #353536,
      //文件库背景色
      filelist-bg: #1b1b1b,
      //preview区外围背景色
      preview-bg: #0f0f10,
      //preview区中间圆角值
      preview-raduis: 0px,
      //preview区关闭按钮hover 颜色
      preview-close-hover-color: #e3e3e3,
      //preview filelist 展开按钮raduis
      preview-filelist-btn-raduis: 0px,
      //preview video and control 边框颜色
      preview-video-border: transparent,
      // preview merge track 隐藏色
      perview-merge-track-bg: #434445,
      //url解析跳转按钮icon默认色
      upload-url-icon-color: #a1a1a2,
      //url解析跳转按钮icon hover色
      upload-url-icon-hover-color: #ffffff,
      //process界面 产品卡片背景色
      process-product-card-bg: #353536,
      //process界面 产品卡片圆角
      process-product-card-raduis: 4px,
      //process 进度条底色
      process-progress-bg: #353536,
      //process 大的进度条的圆角
      process-normal-cont-raduis: 0,
      //process 大的进度条的底色
      process-normal-cont-bg: #3b3c3d,
      //process 大的进度条的字体颜色
      process-normal-cont-color: #ffffff,
      //work 界面的按钮边框颜色
      work-btn-border: rgba(255, 255, 255, 0.1),
      //work 界面的按钮背景颜色
      work-btn-bg: #2e2f30,
      //work 界面的按钮圆角
      work-btn-raduis: 4px,
      //work 界面 hr 颜色
      work-hr-color: rgba(255, 255, 255, 0.1),
      //work 界面hr的宽度
      work-hr-width: 100%,
      //work input 背景颜色
      work-input-bg: #3b3c3d,
      //work 界面 save 按钮 raduis
      work-save-raduis: 2px,
      //work 界面 save 按钮 禁用状态下的背景色
      work-save-disabled-bg: #3b3c3d,
      //work 界面 save 按钮 禁用状态下的边框色
      work-save-disabled-border-color: #3b3c3d,
      //work 界面 save 按钮 禁用状态下的字体颜色
      work-save-disabled-color: #d8d8d8,
      //work 界面icon 特定颜色
      work-spc-btn-color: rgba(255, 255, 255, 0.8),
      //work 界面 item 没有选中的item的滤镜
      work-item-brightness-dark: brightness(0.8),
      // work 界面 slider 默认背景色
      work-slider-bg: #454649,
      //work 界面 resize 背景图选中raduis
      work-resize-bgPic-active-raduis: 4px,
      //work 界面 subtitle 快速定位默认背景色
      work-subtitle-position-bg: #444547,
      //work 界面 addFileBtn 按钮边框颜色
      work-addFileBtn-border: #2e2f30,
      //work 界面 addFile框框 边框颜色
      work-upload-border: #5e5f62,
      //preview 界面 track 背景色
      preview-track-bg: #2e2f30,
      //preview 界面 video 播放器 raduis
      preview-video-cont-raduis: 0px,
      //purchase 弹窗 主字体色
      purchase-color: rgba(255, 255, 255, 0.8),
      //purchase 弹窗 list 标签内容
      purchase-color-secondary: #979798,
      //purchase 弹窗 input
      purchase-input-bg: #3b3c3d,
      //purchase 弹窗 input
      purchase-input-border: #5e5f62,
      //purchase 弹窗 list 标签内容
      purchase-list-before-content:
        url('data:image/svg+xml,<svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M13.1173 0.617205C13.4479 0.947795 13.4615 1.47945 13.1482 1.82647L5.5971 10.1898C5.38645 10.4231 5.02214 10.4288 4.80425 10.2023L0.618497 5.85003C0.275035 5.49291 0.280574 4.92659 0.630955 4.57625C0.958969 4.24828 1.48123 4.21965 1.84313 4.50981L4.91358 6.97163C5.08104 7.10589 5.32254 7.09336 5.47521 6.94249L11.8793 0.613552C12.2227 0.274113 12.7759 0.275744 13.1173 0.617205Z" fill="%23ffffff"/></svg>'),
      //select 下拉icon颜色
      select-arrow-icon-color: #9f9fa1
    )
);
