//通用颜色
$primary-color: #8372ff !default;
$normal-color: #979798 !default;
$white-color-1: #ffffff !default;
$white-color-2: #f7f7fa !default;
$gray-color-2: #5e5f62 !default;
$black: #0f0f10 !default;
$deep-black: #1b1b1b !default;
$dark: #2e2f30 !default;
$deep-dark: #000000 !default;

$deep-gray: #353536 !default;
$gray: #3b3c3d !default;
$low-gray: #5e5f62 !default;

$white: #d8d8d8 !default;
$lightless-white: #e3e3e3 !default;
$light-white: #ffffff !default;
$darkless-white: #a1a1a2 !default;
$darkest-white: #898a8b !default;

$border-gray: #434445 !default;
$border-light: #a1a1a2 !default;

@import "./sass/variables/variables";
@import "./sass/minxin.scss";

.CropRotateWork {
  @include font_color("font-normal");
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 2rem 0 1.5rem;
  overflow: hidden;
  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
    button {
      background-color: $gray;
      border-color: $gray;
      color: $white;
    }
    .setCropBtn {
      color: $normal-color !important;
    }
    .setRotateBtn {
      color: $normal-color !important;
    }
  }
  .ant-btn[disabled] {
    background-color: $gray;
    color: $normal-color;
    border: none;
  }
  button:not(:first-child) {
    margin-top: 0.5rem;
    font-size: 18px;
    line-height: 1;
  }
  .setCropBtn {
    @include background_color("work-btn-bg");
    @include font_color("font-normal");
    @include border("work-btn-border");
    @include border_raduis("work-btn-raduis");
    height: 32px;
    cursor: pointer;
    text-align: center;
    line-height: 32px;
    margin: 0 5px;
    padding: 0 0.5rem;
    &:hover,
    &.active {
      color: $primary-color;
      border-color: $primary-color;
      // box-shadow: 0px 0px 6px rgba(131, 114, 255, 0.2);
    }
  }
  .setRotateBtn {
    @include background_color("work-btn-bg");
    @include font_color("font-normal");
    @include border("work-btn-border");
    @include border_raduis("work-btn-raduis");
    height: 32px;
    cursor: pointer;
    text-align: center;
    line-height: 32px;
    margin: 0 5px;
    &:hover,
    &.active {
      color: $primary-color;
      border-color: $primary-color;
      // box-shadow: 0px 0px 6px rgba(131, 114, 255, 0.2);
    }
  }
  .ant-row {
    hr {
      margin: 20px auto 25px auto;
      border: none;
      @include border("work-hr-color", 1px, solid, border-bottom);
      @include width("work-hr-width");
    }
  }
  input,
  textarea {
    @include background_color("work-btn-bg");
    @include font_color("font-normal");
    @include border("work-btn-border");
    @include border_raduis("work-btn-raduis");
    padding: 4px ​8px !important;
    margin: 0;
    font-size: 14px;
    padding: 2px 0.5rem;
    border-radius: 4px;
    &::-webkit-scrollbar {
      width: 4px;
      @include background_color("scrollbar-track-bg");
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      @include background_color("scrollbar-thumb-bg");
    }

    &::-webkit-scrollbar-track {
      border-radius: 4px;
      @include background_color("scrollbar-track-bg");
    }
  }

  .save {
    padding: 0 1.5rem;
    button {
      font-weight: bold;
      @include border_raduis("work-btn-raduis");
      cursor: pointer;
    }
  }
}
