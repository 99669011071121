//通用颜色
$primary-color: #8372ff !default;
$normal-color: #979798 !default;
$white-color-1: #ffffff !default;
$white-color-2: #f7f7fa !default;
$gray-color-2: #5e5f62 !default;
$black: #0f0f10 !default;
$deep-black: #1b1b1b !default;
$dark: #2e2f30 !default;
$deep-dark: #000000 !default;

$deep-gray: #353536 !default;
$gray: #3b3c3d !default;
$low-gray: #5e5f62 !default;

$white: #d8d8d8 !default;
$lightless-white: #e3e3e3 !default;
$light-white: #ffffff !default;
$darkless-white: #a1a1a2 !default;
$darkest-white: #898a8b !default;

$border-gray: #434445 !default;
$border-light: #a1a1a2 !default;

@import "./sass/variables/variables";
@import "./sass/minxin.scss";

.MergeWork {
  @include font_color("font-normal");
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 2rem 0 1.5rem;
  .fileList {
    padding: 0 1.5rem;
    flex: auto;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 4px;
      @include background_color("scrollbar-track-bg");
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      @include background_color("scrollbar-thumb-bg");
    }
    &::-webkit-scrollbar-track {
      border-radius: 4px;
      @include background_color("scrollbar-track-bg");
    }
    .ant-upload.ant-upload-drag .ant-upload {
      padding: 0;
    }
    .recommendFile {
      position: absolute;
      left: 50%;
      top: 120%;
      transform: translateX(-50%);
      @include border("bg-hight");
      @include border_raduis("raduis-normal");
      text-align: center;
      width: 120px;
      cursor: pointer;
      div {
        position: relative;
        z-index: 2;
        @include background_color("bg-hight");
        @include border_raduis("raduis-normal");
        padding: 10px;
      }
      p {
        @include font_color("font-normal");
        margin-bottom: 6px;
      }
      .img-box {
        background-position: center;
        background-size: cover;
        width: 100px;
        height: 70px;
      }
      &::before {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        @include border("bg-hight", 10px, solid, border-left);
        border-top: solid 12px transparent;
        border-bottom: solid 12px transparent;
        left: 12px;
        top: -12px;
        z-index: -1;
      }
      &::after {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        @include border("bg-hight", 10px, solid, border-left);
        // border-left: solid 10px $dark;
        border-top: solid 12px transparent;
        border-bottom: solid 12px transparent;
        left: 13px;
        top: -10px;
      }
    }
  }
  .ant-upload.ant-upload-drag {
    background: transparent;
    @include border("work-upload-border", 1px, dashed);
    @include border_raduis("raduis-normal");
    width: auto;
    max-width: 100%;
    margin: 0 0 1.25rem;
    min-height: 88px;
    .add {
      text-align: center;
      cursor: pointer;
      padding: 0.75rem 1rem;
      .add-btn {
        @include background_color("work-btn-bg");
        @include font_color("font-normal");
        @include border_raduis("work-btn-raduis");
        @include border("work-addFileBtn-border");
        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          @include font_color("work-spc-btn-color");
          font-size: 14px;
          margin-right: 0.625rem;
        }
      }
      div {
        @include font_color("font-normal");
        margin-bottom: 0.75rem;
        font-size: 14px;
      }
      p {
        @include font_color("font-secondary");
        margin: 0;
        font-size: 12px;
      }
    }
  }
  .ant-upload-list {
    display: none;
  }
  .ant-upload.ant-upload-drag p.ant-upload-text {
    color: $white;
    margin-bottom: 1.25rem;
  }
  .item {
    @include filter("work-item-brightness-dark");
    @include background_color("work-btn-bg");
    @include font_color("font-normal");
    @include border_raduis("work-btn-raduis");
    padding: 1rem;
    cursor: pointer;
    position: relative;
    border: solid 1px transparent;
    margin-bottom: 1rem;
    .img-box {
      background-position: center;
      background-size: cover;
      height: 50px;
    }
    &.active,
    &:hover {
      filter: brightness(1);
      border: solid 1px $primary-color;
      box-shadow: 0px 0px 6px rgba(131, 114, 255, 0.4);
    }
    &:hover {
      .close {
        display: block;
      }
    }
    .close {
      display: none;
      position: absolute;
      background-color: transparent;
      right: 0;
      top: 0;
      border: none;
      padding: 0;
      line-height: 0;
      cursor: pointer;
      &:focus {
        outline: none;
      }
      &:hover {
        color: $primary-color;
      }
    }
  }
  .name {
    font-size: 14px;
    margin-bottom: 0.5rem;
    padding-left: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @include font_color("font-normal");
  }
  .info {
    display: flex;
    justify-content: space-between;
    padding-left: 0.5rem;
    @include font_color("font-secondary");
  }
  .ant-select {
    font-size: 12px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    margin-top: 0.5rem;
  }
  hr {
    border: none;
    @include border("work-hr-color", 1px, solid, border-bottom);
    @include width("work-hr-width");
  }
  .footer {
    padding: 1.25rem 1.5rem 0;
    .ant-select {
      margin-bottom: 1rem;
      .ant-select-selector {
        border-radius: 4px;
        height: 32px;
        padding: 4px 12px;
        font-size: 14px;
      }
    }
    .disabled {
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
      button {
        @include background_color("work-save-disabled-bg");
        @include border("work-save-disabled-border-color");
        @include font_color("work-save-disabled-color");
      }
    }
    button {
      margin-top: 1rem;
      font-size: 16px;
      font-weight: bold;
      line-height: 1;
      @include border_raduis("work-save-raduis");
    }
    .color-picker {
      display: flex;
      align-items: center;
      .color-picker-form {
        @include background_color("work-btn-bg");
        @include font_color("font-normal");
        @include border_raduis("work-btn-raduis");
        width: 96px;
        padding: 0;
        display: flex;
        align-items: center;
        padding: 8px 7px;
        font-size: 14px;
        line-height: 14px;
        margin-right: 4px;
        & > span {
          border: none;
          padding: 0;
          margin: 0;
          width: auto;
          height: auto;
          border-radius: 0;
          background-color: transparent;
          margin-right: 6px;
        }
        & > span > span {
          height: 18px;
          width: 18px;
          @include font_color("font-normal");
          background-image: url("./images/blur.png");
          background-size: contain;
          background-repeat: no-repeat;
          margin: 0;
          background-color: inherit !important;
        }
      }
      .presetColor {
        background-color: transparent;
        padding: 4px;
        cursor: pointer;
        position: relative;
        div {
          width: 18px;
          height: 18px;
          border-radius: 100%;
          background-size: cover !important;
        }
        &.active::before {
          content: "";
          position: absolute;
          width: 24px;
          height: 24px;
          border: 1px solid #a1a1a2;
          top: 1px;
          left: 1px;
          border-radius: 50%;
        }
      }
    }
  }
  .css-1j0mc3w-ColorPicker input {
    border: solid 1px $normal-color;
  }
}
